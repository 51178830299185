import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import Tables from '../../components/Tables';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getLoanByUserIdAPI, deleteLoanAPI, updateMultipleLoanStatusAPI, updateLoanStatusAPI } from '../../services/api';
import { Badge } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { getToken } from 'services/dataService';
import { Button } from 'reactstrap';
const LoanList = () => {
    const [loanData, setLoanData] = useState([]);
    const data = loanData;
    const [show, setShow] = useState(false);
    const [statusAlert, setStatusAlert] = useState(false);
    const [id, setID] = useState(null);
    const [loanStatus, setLoanStatus] = useState(null);

    const history = useHistory();

    // const uploadDocument = (e, data) => {

    //     e.preventDefault();
    //     history.push(`application/document-upload/${data.id}`, {
    //         userId: data.userId,
    //     });
    // };

    // const redirectToLinkBank = (e, data) => {
    //     e.preventDefault();
    //     history.push(`application/link-bank/${data.id}/${data.userId}`, {
    //         userId: data.userId,
    //     });
    // };

    const docVerificationToast = (e) => {
        e.preventDefault();
        toast.error('Document verification pending.');
    };

    const continueClick = (row) => {
        console.log(row);
        if (row?.tcStatus === '0') {
            return (
                <Link
                    to={`/view-application/${row?.id}`}
                    className="table-action table-action-toggle"
                    data-toggle="tooltip"
                    title="Continue Application"
                    data-original-title="Edit product"
                >
                    <Button className="text-white btn-sm btn-success">Continue</Button>
                </Link>
            );
        } else {
            if (
                (row.addressProofStatus === '2' &&
                    row.addressProof === null &&
                    row.backLicenceStatus === '2' &&
                    row.backLicence === null &&
                    row.frontLicenceStatus === '2' &&
                    row.frontLicence === null &&
                    row.selfieStatus === '2' &&
                    row.selfie === null) ||
                (row.addressProofStatus === null &&
                    row.addressProof === null &&
                    row.backLicenceStatus === null &&
                    row.backLicence === null &&
                    row.frontLicenceStatus === null &&
                    row.frontLicence === null &&
                    row.selfieStatus === null &&
                    row.selfie === null)
            ) {
                return (
                    <Link
                        to={{ pathname: `/application/document-upload/${row?.id}` }}
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        title="Continue Application"
                        data-original-title="Edit product"
                    >
                        <Button className="text-white btn-sm btn-success">Continue</Button>
                    </Link>
                );
            }
            if (
                row.addressProofStatus === '2' &&
                row.addressProof &&
                row.backLicenceStatus === '2' &&
                row.backLicence &&
                row.frontLicenceStatus === '2' &&
                row.frontLicence &&
                row.selfieStatus === '2' &&
                row.selfie
            ) {
                return (
                    <Link
                        to={`/application/document-under-process/${row?.id}`}
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        title="Continue Application"
                        data-original-title="Edit product"
                    >
                        <Button className="text-white btn-sm btn-success">Continue</Button>
                    </Link>
                );
            }
            if (
                row.addressProofStatus === '1' &&
                row.backLicenceStatus === '1' &&
                row.frontLicenceStatus === '1' &&
                row.selfieStatus === '1'
            ) {
                return (
                    <Link
                        to={`/application/document-approved/${row?.id}`}
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        title="Continue Application"
                        data-original-title="Edit product"
                    >
                        <Button className="text-white btn-sm btn-success">Continue</Button>
                    </Link>
                );
            }
            if (
                row.addressProofStatus === '0' ||
                row.backLicenceStatus === '0' ||
                row.frontLicenceStatus === '0' ||
                row.selfieStatus === '0'
            ) {
                return (
                    <Link
                        to={{
                            pathname: `/application/document-rejected/${row?.id}`,
                        }}
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        title="Continue Application"
                        data-original-title="Edit product"
                    >
                        <Button className="text-white btn-sm btn-success">Continue</Button>
                    </Link>
                );
            }
            return (
                <Link
                    to={`/application/document-under-process/${row?.id}`}
                    className="table-action table-action-toggle"
                    data-toggle="tooltip"
                    title="Continue Application"
                    data-original-title="Edit product"
                >
                    <Button className="text-white btn-sm btn-success">Continue</Button>
                </Link>
            );
        }
    };

    const columns = [
        { dataField: 'id', text: 'id', sort: true, hidden: true },
        {
            dataField: 'createdAt',
            text: 'Apply Date',
            sort: true,
            formatter: (cell, row) => <>{row.createdAt ? moment.utc(row.createdAt).format('DD-MM-YYYY') : 'N/A'}</>,
        },
        { dataField: 'accountNo', text: 'Loan Account No', sort: true },
        { dataField: 'initialAmount', text: 'Initial Amount', sort: true },
        { dataField: 'months', text: 'Tenure', sort: true },
        { dataField: 'repaymentMonth', text: 'Monthly Installments', sort: true },
        {
            dataField: 'documentStatus',
            text: 'Document Status',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.addressProofStatus === '2' &&
                    row.addressProof === null &&
                    row.backLicenceStatus === '2' &&
                    row.backLicence === null &&
                    row.frontLicenceStatus === '2' &&
                    row.frontLicence === null &&
                    row.selfieStatus === '2' &&
                    row.selfie === null ? (
                        // <Link
                        //     to={`/view-application/${row.id}`}
                        //     className="table-action table-action-toggle"
                        //     data-toggle="tooltip"
                        //     title="Pending Document"
                        //     data-original-title="Edit product"
                        // >
                        <Badge className="cursor-pointer bg-yellow text-white badge-default">Pending</Badge>
                    ) : // </Link>
                    row.addressProofStatus === '2' &&
                      row.addressProof &&
                      row.backLicenceStatus === '2' &&
                      row.backLicence &&
                      row.frontLicenceStatus === '2' &&
                      row.frontLicence &&
                      row.selfieStatus === '2' &&
                      row.selfie ? (
                        <Badge className="bg-info text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Submitted
                        </Badge>
                    ) : row.addressProofStatus === '1' &&
                      row.backLicenceStatus === '1' &&
                      row.frontLicenceStatus === '1' &&
                      row.selfieStatus === '1' ? (
                        <Badge className="bg-success text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Approved
                        </Badge>
                    ) : row.addressProofStatus === '0' ||
                      row.backLicenceStatus === '0' ||
                      row.frontLicenceStatus === '0' ||
                      row.selfieStatus === '0' ? (
                        <Badge className="bg-danger text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Rejected
                        </Badge>
                    ) : (
                        <Badge
                            className="cursor-pointer bg-yellow text-white badge-default"
                            // onClick={(e) => uploadDocument(e, row)}
                            onClick={(e) => e.preventDefault()}
                        >
                            Pending
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: 'loanStatus',
            text: 'Loan Status',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.loanStatus === '2' ? (
                        <Badge className="bg-yellow text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Pending
                        </Badge>
                    ) : row.loanStatus === '1' ? (
                        <Badge className="bg-success text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Approved
                        </Badge>
                    ) : (
                        <Badge className="bg-danger text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Rejected
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: 'bankStatus',
            text: 'Bank Details Status',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.addressProofStatus === '1' &&
                    row.backLicenceStatus === '1' &&
                    row.frontLicenceStatus === '1' &&
                    row.selfieStatus === '1' ? (
                        <>
                            {row.bankStatus === '2' ? (
                                <Badge className="bg-info text-white badge-default" onClick={(e) => e.preventDefault()}>
                                    Submitted
                                </Badge>
                            ) : row.bankStatus === '1' ? (
                                <Badge className="bg-success text-white badge-default" onClick={(e) => e.preventDefault()}>
                                    Approve
                                </Badge>
                            ) : row.bankStatus === '0' ? (
                                <Badge
                                    className="bg-danger text-white badge-default "
                                    // className="bg-danger text-white badge-default cursor-pointer"
                                    // onClick={(e) => redirectToLinkBank(e, row)}
                                >
                                    Reject
                                </Badge>
                            ) : (
                                <Badge
                                    className="bg-yellow text-white badge-default"
                                    // onClick={(e) => redirectToLinkBank(e, row)}
                                >
                                    Pending
                                </Badge>
                            )}
                        </>
                    ) : (
                        <Badge className="bg-yellow text-white badge-default cursor-pointer">
                            {/* onClick={(e) => docVerificationToast(e)} */}
                            Pending
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: 'actions',
            text: 'Action',
            isDummyField: true,
            formatter: (cell, row) => continueClick(row),
        },
    ];

    const SweetAlertFun = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={show}
                    onCancel={() => setShow(false)}
                    onConfirm={() => deleteLoan()}
                ></SweetAlert>
            </>
        );
    };

    const SweetAlertFunToggle = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, update status!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={statusAlert}
                    onCancel={() => setStatusAlert(false)}
                    onConfirm={() => updateStatus()}
                ></SweetAlert>
            </>
        );
    };

    const deleteLoan = () => {
        deleteLoanAPI(id).then((result) => {
            if (result) {
                setShow(false);
                toast.success('Record deleted successfully.');
                getAllLoanByUserId();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    const updateStatus = () => {
        const status = { loanStatus: loanStatus };
        updateLoanStatusAPI(id, status).then((result) => {
            if (result) {
                setStatusAlert(false);
                toast.success('Status updated successfully.');
                getAllLoanByUserId();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    useEffect(() => {
        getAllLoanByUserId();
    }, []);

    const getAllLoanByUserId = () => {
        const tokenData = jwtDecode(getToken());
        getLoanByUserIdAPI(tokenData.user.id).then((user) => {
            setLoanData(user);
        });
    };

    const updateMultipleLoanStatus = (selectedRow, selectionType) => {
        updateMultipleLoanStatusAPI({
            IDArray: selectedRow,
            status: selectionType,
        }).then((result) => {
            if (result) {
                setShow(false);
                toast.success('Status updated successfully.');
                getAllLoanByUserId();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    return (
        <>
            <Breadcrumbs breadcrumbName="Loan Application" />
            <SweetAlertFun />
            <SweetAlertFunToggle />
            <Tables
                data={data}
                columns={columns}
                defaultSortKey="createdAt"
                defaultSortDirection="desc"
                currentPath="loan"
                tableTitle="Applications"
                updateMultipleStatus={(selectedRow, selectionType) => updateMultipleLoanStatus(selectedRow, selectionType)}
                showSearchBar={true}
            />
        </>
    );
};

export default LoanList;
