import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumbs';

const DocumentUnderProcess = (props) => {
	return (
		<>
			<Breadcrumbs breadcrumbName='Identity Verification' />
			{/* Page content */}
			<Container className='mt--7' fluid>
				<Row>
					<Col className='order-xl-1' xl='12'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-white border-0'>
								<Row className='align-items-center'>
									<Col xs='8'>
										<h2 className='mb-0'>Under Process</h2>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-md-6 col-12 my-auto'>
										<h3 className='text-green mb-4'>
											The documents you've sent are currently being checked.
											This method checks to see if the account is being used by
											a real person who lives at the same address as provided to
											Us.
											<br />
											<br />
											We must go through several verification steps in order to
											validate the documentation given by the customer, so the
											process can take a long time.
										</h3>
										{props?.location?.state?.remarks && <div className='pb-4'><strong>Remarks: </strong>
											{props?.location?.state?.remarks}
										</div>}
									</div>
									<div className='col-md-6 col-12'>
										<img
											src={
												require('../../assets/dashboard_assets/img/brand/document-upload/identity-verification-under-process.png')
													.default
											}
											className='img-fluid'
											alt=''
										/>
									</div>
									<div className='mt-4'></div>
								</div>
								<hr />
								<Col md='12' className='text-right'>
									<Link to={'/dashboard'}>
										<Button className='mr-3 text-black btn-info'>Return To Dashboard</Button>
									</Link>
								</Col>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default DocumentUnderProcess;
