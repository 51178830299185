import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';

const DocumentApproved = (props) => {
    const [isTCAccept, setIsTCAccept] = useState(false);
    const tokenData = jwtDecode(getToken());

    const onAcceptTc = (e) => {
        setIsTCAccept(e.target.checked);
    };
    return (
        <>
            <Breadcrumbs breadcrumbName="Identity Verification" />
            {/* Page content */}

            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h2 className="mb-0">Completed</h2>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6 col-12 my-auto">
                                        <h3 className="text-green mb-4">
                                            Congratulations on your achievement! It gives us great pleasure to inform you that our Easy Loan
                                            America Verification Team has thoroughly examined your documents.
                                            <br />
                                            <br />
                                            Please press the button below to proceed with your submission.
                                        </h3>
                                        {props?.location?.state?.remarks && <div className='pb-4'><strong>Remarks: </strong>
                                            {props?.location?.state?.remarks}
                                        </div>}
                                        <Col md="12" className="text-center">
                                            <div className="custom-control custom-control-alternative custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    id=" customCheckLogin"
                                                    type="checkbox"
                                                    onChange={(e) => onAcceptTc(e)}
                                                />
                                                <label className="custom-control-label" htmlFor=" customCheckLogin">
                                                    <span className="text-muted">I here by accept all Terms & Conditions</span>
                                                </label>
                                            </div>
                                        </Col>
                                        <br />
                                        {isTCAccept && (
                                            <Col md="12" className="text-center">
                                                <Link to={'/application/link-bank/' + props.match.params.id + '/' + tokenData.user.id}>
                                                    <Button className="btn-success mr-3 text-black">Continue</Button>
                                                </Link>
                                            </Col>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <img
                                            src={
                                                require('../../assets/dashboard_assets/img/brand/document-upload/identity-verification-completed.png')
                                                    .default
                                            }
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DocumentApproved;
