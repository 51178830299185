import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { getLoanByIdAPI } from 'services/api';

import Breadcrumbs from '../../components/Breadcrumbs';

const DocumentRejected = (props) => {
    const history = useHistory();
    const params = useParams();
    const loanID = params?.id;
    const [remarks, setRemarks] = useState('');

    useEffect(() => {
		getLoanByIdAPI(loanID).then(data => {
			if (data?.length > 0) {
				setRemarks(data[0]['rejectReason'])
			}
		});
	}, [loanID]);

    return (
        <>
            <Breadcrumbs breadcrumbName="Identity Verification" />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h2 className="mb-0">Rejected</h2>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6 col-12 my-auto">
                                        <h3 className="text-green mb-4">
                                            The documents you sent for the process verification have been denied. During
                                            the procedure's inspection, our Rapid Cash America Verification Team
                                            discovered that one or more of the documents you submitted was incomplete or
                                            invalid.
                                            <br />
                                            <br />
                                            To prevent rejection when applying for a loan, we recommend that you
                                            re-submit the VALID & CLEAR documents.
                                        </h3>
                                        {remarks &&
                                            <div className='pb-4'><strong>Remarks: </strong>
                                                {remarks}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <img
                                            src={
                                                require('../../assets/dashboard_assets/img/brand/document-upload/identity-verification-rejected.png')
                                                    .default
                                            }
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <button
                                            onClick={() =>
                                                history.push(
                                                    `/application/document-upload/${params?.id}`)
                                            }
                                            className="text-white btn-info btn"
                                        >
                                            Re-upload documents
                                        </button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DocumentRejected;
