export var setToken = (tokenData) => {
	tokenData && localStorage.setItem('client_token', tokenData);
	return true;
};

export var getToken = () => {
	return localStorage.getItem('client_token');
};

export const logout = () => {
	localStorage.clear();
	window.location.replace('https://eazyloanamerica.com/');
};