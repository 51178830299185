import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';
import { getBankDetailsByIdAPI } from 'services/api';

const BankRejectProcess = (props) => {
    const [isTCAccept, setIsTCAccept] = useState(false);
    const tokenData = jwtDecode(getToken());
	const [linkBankDet, setLinkBankDet] = useState({});

	useEffect(() => {
		getBankDetailsByIdAPI(props.match.params.id).then(data => {
			setLinkBankDet(data);
		});
	}, []);

    const onAcceptTc = (e) => {
        setIsTCAccept(e.target.checked);
    };

    return (
        <>
            <Breadcrumbs breadcrumbName="Bank Verification" />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h2 className="mb-0">Rejected</h2>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6 col-12 my-auto">
                                        <h3 className="text-green mb-4">
                                            Your bank details, which were submitted for process verification, have been denied. During the
                                            procedure's verification, our Rapid Cash America Verification Team discovered that one or more
                                            of the bank details you submitted was incomplete or invalid.
                                            <br />
                                        </h3>
                                        {linkBankDet?.reason &&
											<div className='pb-4'><strong>Remarks: </strong>
												{linkBankDet?.reason}
										</div>}
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <img
                                            src={require('../../assets/dashboard_assets/img/brand/document-upload/bank-reject.png').default}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4"></div>
                                </div>
                                <Col md="12" className="text-left">
                                    <div className="custom-control custom-control-alternative custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id=" customCheckLogin"
                                            type="checkbox"
                                            onChange={(e) => onAcceptTc(e)}
                                        />
                                        <label className="custom-control-label" htmlFor=" customCheckLogin">
                                            <span className="text-muted">I here by accept all Terms & Conditions</span>
                                        </label>
                                    </div>
                                </Col>
                                <hr />
                                <Col md="12" className="text-center">
                                    <Link to={'/application/link-bank/' + props.match.params.id + '/' + tokenData.user.id}>
                                        <Button disabled={!isTCAccept} className="btn-success mr-3 text-black">
                                            Re-Submit
                                        </Button>
                                    </Link>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default BankRejectProcess;
