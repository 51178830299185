import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumbs';
import { getBankDetailsByIdAPI } from 'services/api';
import { useEffect, useState } from 'react';

const BankVerifyProcess = (props) => {
	const [linkBankDet, setLinkBankDet] = useState({});

	useEffect(() => {
		getBankDetailsByIdAPI(props.match.params.id).then(data => {
			setLinkBankDet(data);
		});
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='Bank Account Verification' />
			{/* Page content */}
			<Container className='mt--7' fluid>
				<Row>
					<Col className='order-xl-1' xl='12'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-white border-0'>
								<Row className='align-items-center'>
									<Col xs='8'>
										<h2 className='mb-0'>Completed</h2>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-md-6 col-12 my-auto'>
										<h3 className='text-green mb-4'>
										The bank account information you provided is being
										checked. You will be informed once our Easy Loan America 
										Verification Team has successfully verified your bank data. 
											<br />
											<br />
											This procedure ensures that the user's bank details are accurate and legal in every way. 
										</h3>
										{linkBankDet?.reason &&
											<div className='pb-4'><strong>Remarks: </strong>
												{linkBankDet?.reason}
										</div>}
									</div>
									<div className='col-md-6 col-12'>
										<img
											src={
												require('../../assets/dashboard_assets/img/brand/document-upload/bank-completed.png')
													.default
											}
											className='img-fluid'
											alt=''
										/>
									</div>
									<div className='mt-4'></div>
								</div>
								<hr />
								<Col md='12' className='text-right'>
									<Link to={'/dashboard'}>
										<Button className='mr-3 text-black btn-info'>Return To Dashboard</Button>
									</Link>
								</Col>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default BankVerifyProcess;
