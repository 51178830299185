import React from 'react';

import { toast } from 'react-toastify';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Row,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from 'reactstrap';

import { createNoAuthUserAPI, checkForEmail } from '../services/api';
import { useHistory, Link } from 'react-router-dom';

const Signup = () => {
	const history = useHistory();

	const formFields = {
		firstName: '',
		lastName: '',
		middleName: '',
		phoneNo1: '',
		email: '',
		password: '',
		confirmPassword: '',
	};

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		middleName: Yup.string().required('Middle Name is required'),
		phoneNo1: Yup.string().required('Phone No is required'),
		email: Yup.string().email('Email is invalid').required('Email is required'),
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.required('Password is required'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Confirm Password is required'),
	});

	var addUser = (data) => {
		checkForEmail({ email: data.email }, true).then((result) => {
			if (result && result.success) {
				createNoAuthUserAPI(data).then((result) => {
					if (result.result) {
						toast.success(
							'Account created successfully, Please login to access EazyLoanAmerica services.'
						);
						history.push('/login');
					} else {
						toast.error('Something went wrong.');
					}
				});
			} else {
				toast.error(result && result.message);
				return;
			}
		});
	};

	return (
		<>
			<Col lg='5' md='12' sm='12'>
				<Card className='login-box border-0'>
					<CardHeader className='bg-transparent pb-4'>
						<div className='text-muted text-center mt-2 mb-3'>
							<img
								alt='...'
								width='170'
								height='90'
								src={
									require('../assets/dashboard_assets/img/brand/logo.png')
										.default
								}
							/>
						</div>
					</CardHeader>
					<CardBody className='px-lg-4 py-lg-4'>
						<Formik
							onSubmit={(values, actions) => {
								addUser(values);
							}}
							initialValues={formFields}
							enableReinitialize
							validationSchema={validationSchema}
							render={({ handleSubmit, errors, touched }) => (
								<form onSubmit={handleSubmit}>
									<Row>
										<Col lg='6'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-user' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														name='firstName'
														type='text'
														id='input-first-name'
														placeholder='First name'
														className={
															'form-control-alternative form-control' +
															(errors.firstName && touched.firstName
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='firstName'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-user' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														name='middleName'
														type='text'
														id='input-middle-name'
														placeholder='Middle name'
														className={
															'form-control-alternative form-control' +
															(errors.middleName && touched.middleName
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='middleName'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-user' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														id='input-last-name'
														placeholder='Last name'
														name='lastName'
														type='text'
														className={
															'form-control-alternative form-control' +
															(errors.lastName && touched.lastName
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='lastName'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-phone-alt' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														id='input-phone-no'
														placeholder='Phone no'
														name='phoneNo1'
														type='number'
														className={
															'form-control-alternative form-control' +
															(errors.phoneNo1 && touched.phoneNo1
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='phoneNo1'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
										<Col lg='12'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-envelope' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														id='input-email'
														placeholder='Email'
														type='email'
														name='email'
														className={
															'form-control-alternative form-control' +
															(errors.email && touched.email
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='email'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-lock' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														id='input-password'
														placeholder='Password'
														type='password'
														name='password'
														className={
															'form-control-alternative form-control' +
															(errors.password && touched.password
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='password'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-lock' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														id='input-confirm-password'
														placeholder='Confirm password'
														type='password'
														name='confirmPassword'
														className={
															'form-control-alternative form-control' +
															(errors.confirmPassword && touched.confirmPassword
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='confirmPassword'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col className='text-right' xs='12'>
											<span>Already have an account ? </span>
											<Link to='/login' className='text-blue'>
												<span>Login</span>
											</Link>
										</Col>
									</Row>
									<div className='text-center'>
										<Button
											// onClick={(e) => e.preventDefault()}
											className='mt-4 mb-2 btn-success text-white'
											type='submit'>
											Signup
										</Button>
									</div>
								</form>
							)}
						/>
					</CardBody>
				</Card>
			</Col>

			<Col lg='5' md='12' sm='12' className='login-img-margin'>
				<div className='row gy-4'>
					<img
						src={
							require('../assets/dashboard_assets/img/brand/login-img.png')
								.default
						}
						className='img-fluid'
						alt=''
					/>
				</div>
			</Col>
		</>
	);
};

export default Signup;
