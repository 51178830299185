import Dashboard from './views/Dashboard';
import WalletList from './views/Wallet/WalletList';
import WalletView from './views/Wallet/WalletView';
import LinkBankDetails from './views/LinkBankDetails';
import Profile from './views/Profile.js';
import Login from './views/Login.js';
import Signup from './views/Signup.js';
import LoanList from './views/Loan/LoanList';
import LoanView from './views/Loan/LoanView';

// Front
import DocumentUpload from './views/Documents/DocumentUpload';
import DocumentUnderProcess from './views/Documents/DocumentUnderProcess';
import DocumentRejected from './views/Documents/DocumentRejected';
import DocumentApproved from 'views/Documents/DocumentApproved';
import BankUnderProcess from 'views/Documents/BankUnderProcess';
import BankRejectProcess from 'views/Documents/BankRejectProcess';
import BankVerifyProcess from 'views/Documents/BankVerifyProcess';
import ResetPassword from 'views/ResetPassword';
import ForgotPassword from 'views/ForgotPassword';

var routes = [
    /* front dashboard */
    {
        path: '/user',
        name: 'Dashboard',
        icon: 'fas fa-credit-card text-green',
        component: Dashboard,
        layout: '/admin',
    },
    {
        path: '/wallet/:id/:paymentMethod',
        name: 'Wallet Transaction Management',
        component: WalletView,
        layout: '/admin',
    },
    {
        path: '/wallet',
        name: 'Wallet Transaction Management',
        icon: 'fas fa-credit-card text-black',
        component: WalletList,
        layout: '/admin',
    },
    {
        path: '/apply-for-loan',
        name: 'New Application',
        icon: 'fas fa-plus text-primary',
        component: LoanList,
        onclick: true,
        layout: '/admin',
    },
    {
        path: '/view-application/:id',
        component: LoanView,
        layout: '/admin',
    },
    {
        path: '/application/document-upload/:id',
        name: 'Document Upload',
        component: DocumentUpload,
        layout: '/admin',
    },
    {
        path: '/application/document-approved/:id',
        name: 'Document Approved',
        component: DocumentApproved,
        layout: '/admin',
    },
    {
        path: '/application/document-rejected/:id',
        name: 'Document Rejected',
        component: DocumentRejected,
        layout: '/admin',
    },
    {
        path: '/application/document-under-process/:id',
        name: 'Document Under Process',
        component: DocumentUnderProcess,
        layout: '/admin',
    },
    {
        path: '/application/bank-under-process/:id',
        name: 'Bank Under Process',
        component: BankUnderProcess,
        layout: '/admin',
    },
    {
        path: '/application/bank-reject-process/:id',
        name: 'Bank Reject Process',
        component: BankRejectProcess,
        layout: '/admin',
    },
    {
        path: '/application/bank-verified-process/:id',
        name: 'Bank Verified Process',
        component: BankVerifyProcess,
        layout: '/admin',
    },
    {
        path: '/application/link-bank/:lid/:uid',
        name: 'Link Bank',
        component: LinkBankDetails,
        layout: '/admin',
    },
    {
        path: '/application',
        name: 'View Application',
        icon: 'fas fa-eye text-orange',
        component: LoanList,
        layout: '/admin',
    },
    {
        path: '/user-profile',
        name: 'Account',
        icon: 'fas fa-user text-yellow',
        component: Profile,
        layout: '/admin',
    },
    {
        path: '/',
        component: Dashboard,
        layout: '/admin',
    },

    /* logout */
    {
        path: '/logout',
        name: 'Logout',
        icon: 'fas fa-sign-out-alt text-red',
        layout: '/admin',
        onclick: true,
    },
    /* login */
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        layout: '/auth',
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        layout: '/auth',
    },
    {
        path: '/reset-password/:userId',
        name: 'ResetPassword',
        component: ResetPassword,
        layout: '/auth',
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        layout: '/auth',
    },
];
export default routes;
