import React from 'react';
import {
	useLocation,
	Route,
	Switch,
	Redirect,
	useHistory,
} from 'react-router-dom';
// reactstrap components
import { Container, Row } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import routes from '../routes.js';

const Auth = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		if (
			localStorage.getItem('client_token') &&
			props.location.pathname.indexOf('/signup') === -1
		) {
			history.replace('/dashboard');
		}
	}, []);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/auth') {
				require('../assets/dashboard_assets/scss/style.scss');
				return <Route path={prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
			<div className='main-content login-bg' ref={mainContent}>
				{/* Page content */}
				<Container fluid>
					<Row>
						<Switch>
							{getRoutes(routes)}
							<Redirect from='*' to='/login' />
						</Switch>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Auth;
