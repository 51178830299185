import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';
import Breadcrumbs from '../../components/Breadcrumbs';
import { toast } from 'react-toastify';
import {
	createWalletAPI,
	getUserByIdAPI,
	getDashboardDataApi,
} from '../../services/api';
import { useHistory } from 'react-router-dom';

const WalletView = (props) => {
	const paymentMethod = props?.match?.params?.paymentMethod || '';
	const buttonName = useState('Submit');
	const [dashboardData, setDashboardData] = useState(null);
	const [selectedUserDetail, setSelectedUserDetails] = useState({});
	const history = useHistory();

	const formFields = {
		withdrawalAmount: '',

		debitCardDebitCardNo: '',
		debitCardExpiryMonth: '',
		debitCardExpiryYear: '',
		debitCardCvv: '',
		debitCardCardName: '',
		debitCardBankName: '',

		cashAppCashCardNo: '',
		cashAppExpiryMonth: '',
		cashAppExpiryYear: '',
		cashAppCvv: '',
		cashAppCardName: '',
		cashAppCashTag: '',

		moneyPakNumber: '',
		moneyPakFirstName: '',
		moneyPakLastName: '',

		zellePhoneNo: '',
		zelleFirstName: '',
		zelleLastName: '',
		zelleEmail: '',

		googlePayPhoneNo: '',
		googlePayFirstName: '',
		googlePayLastName: '',

		applePayPhoneNo: '',
		applePayFirstName: '',
		applePayLastName: '',

		paypalPhoneNo: '',
		paypalFirstName: '',
		paypalLastName: '',
		paypalEmail: '',
	};

	const validationSchema = Yup.object().shape({
		withdrawalAmount: Yup.string().required("WithdrawalAmount is required"),

		debitCardDebitCardNo: paymentMethod === 'debit-card' ? Yup.string().required("Debit Card No is required") : Yup.string(),
		debitCardExpiryMonth: paymentMethod === 'debit-card' ? Yup.string().required("Expiry Month is required").max(2) : Yup.string().max(2),
		debitCardExpiryYear: paymentMethod === 'debit-card' ? Yup.string().required("Expiry year is required").max(4) : Yup.string().max(4),
		debitCardCvv: paymentMethod === 'debit-card' ? Yup.string().required("Cvv is required") : Yup.string().max(3),
		debitCardCardName: paymentMethod === 'debit-card' ? Yup.string().required("Name on card is required") : Yup.string(),
		debitCardBankName: paymentMethod === 'debit-card' ? Yup.string().required("Bank name is required") : Yup.string(),

		cashAppCashCardNo: paymentMethod === 'cash-app' ? Yup.string().required("Cash app No is required") : Yup.string(),
		cashAppExpiryMonth: paymentMethod === 'cash-app' ? Yup.string().required("Expiry month is required").max(2) : Yup.string().max(2),
		cashAppExpiryYear: paymentMethod === 'cash-app' ? Yup.string().required("Expiry year is required").max(4) : Yup.string().max(4),
		cashAppCvv: paymentMethod === 'cash-app' ? Yup.string().required("Cvv is required") : Yup.string().max(3),
		cashAppCardName: paymentMethod === 'cash-app' ? Yup.string().required("Name on card is required") : Yup.string(),
		cashAppCashTag: paymentMethod === 'cash-app' ? Yup.string().required("Cash tag is required") : Yup.string(),

		moneyPakNumber: paymentMethod === 'money-pak' ? Yup.string().required("Money-pak number is required") : Yup.string(),
		moneyPakFirstName: paymentMethod === 'money-pak' ? Yup.string().required("First name is required") : Yup.string(),
		moneyPakLastName: paymentMethod === 'money-pak' ? Yup.string().required("Last name is required") : Yup.string(),

		zellePhoneNo: paymentMethod === 'zelle' ? Yup.string().required("Phone no is required") : Yup.string(),
		zelleFirstName: paymentMethod === 'zelle' ? Yup.string().required("First name is required") : Yup.string(),
		zelleLastName: paymentMethod === 'zelle' ? Yup.string().required("Last name is required") : Yup.string(),
		zelleEmail: paymentMethod === 'zelle' ? Yup.string().email('Invalid email').required("Email address is required") : Yup.string(),

		googlePayPhoneNo: paymentMethod === 'google-pay' ? Yup.string().required("Phone no is required") : Yup.string(),
		googlePayFirstName: paymentMethod === 'google-pay' ? Yup.string().required("First name is required") : Yup.string(),
		googlePayLastName: paymentMethod === 'google-pay' ? Yup.string().required("Last name is required") : Yup.string(),

		applePayPhoneNo: paymentMethod === 'apple-pay' ? Yup.string().required("Phone no is required") : Yup.string(),
		applePayFirstName: paymentMethod === 'apple-pay' ? Yup.string().required("First name is required") : Yup.string(),
		applePayLastName: paymentMethod === 'apple-pay' ? Yup.string().required("Last name is required") : Yup.string(),

		paypalPhoneNo: paymentMethod === 'paypal' ? Yup.string().required("Phone no is required") : Yup.string(),
		paypalFirstName: paymentMethod === 'paypal' ? Yup.string().required("First name is required") : Yup.string(),
		paypalLastName: paymentMethod === 'paypal' ? Yup.string().required("Last name is required") : Yup.string(),
		paypalEmail: paymentMethod === 'paypal' ? Yup.string().email('Invalid email').required("Email is required") : Yup.string(),
	});

	var addWallet = (data) => {
		if ((paymentMethod === 'debit-card' &&
			Number(data.withdrawalAmount) +
			Number(dashboardData?.totalWithdrawlAmountOfUser) >
			Number(dashboardData?.totalLoanAmountOfUser) ||
			data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser)) ||
			(paymentMethod === 'cash-app' &&
				Number(data.withdrawalAmount) +
				Number(dashboardData?.totalWithdrawlAmountOfUser) >
				Number(dashboardData?.totalLoanAmountOfUser) ||
				data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser)) ||
			(paymentMethod === 'money-pak' &&
				Number(data.withdrawalAmount) +
				Number(dashboardData?.totalWithdrawlAmountOfUser) >
				Number(dashboardData?.totalLoanAmountOfUser) ||
				data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser)) ||
			(paymentMethod === 'zelle' &&
				Number(data.withdrawalAmount) +
				Number(dashboardData?.totalWithdrawlAmountOfUser) >
				Number(dashboardData?.totalLoanAmountOfUser) ||
				data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser)) ||
			(paymentMethod === 'google-pay' &&
				Number(data.withdrawalAmount) +
				Number(dashboardData?.totalWithdrawlAmountOfUser) >
				Number(dashboardData?.totalLoanAmountOfUser) ||
				data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser)) ||
			(paymentMethod === 'apple-pay' &&
				Number(data.withdrawalAmount) +
				Number(dashboardData?.totalWithdrawlAmountOfUser) >
				Number(dashboardData?.totalLoanAmountOfUser) ||
				data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser)) ||
			(paymentMethod === 'paypal' &&
				Number(data.withdrawalAmount) +
				Number(dashboardData?.totalWithdrawlAmountOfUser) >
				Number(dashboardData?.totalLoanAmountOfUser) ||
				data.withdrawalAmount > Number(dashboardData?.totalLoanAmountOfUser))
		) {
			toast.error('Withdrawal Amount should be lower.');
			return;
		}

		var walletAdd = {
			...data,
			userId: selectedUserDetail.userId,
			paymentMethod,
		};
		createWalletAPI(walletAdd).then((result) => {
			if (result.result) {
				toast.success('Record added successfully.');
				history.push('/wallet');
			} else {
				toast.error('Something went wrong.');
			}
		});

	};

	useEffect(() => {
		const tokenData = jwtDecode(getToken());
		getDashboardDataApi({ userId: tokenData.user.id }).then((result) => {
			if (result) {
				setDashboardData(result);
			}
		});
		getUserByIdAPI(props.match.params.id).then((user) => {
			setSelectedUserDetails({
				userId: user.id,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='Wallet Management' />
			{/* Page content */}
			<Formik
				onSubmit={(values, actions) => {
					addWallet(values);
				}}
				initialValues={formFields}
				enableReinitialize
				validationSchema={validationSchema}
				render={({ handleSubmit, errors, touched }) => (
					<Container className='mt--7' fluid>
						<Row>
							<Col className='order-xl-1' xl='12'>

								{/* Debit Card*/}
								{paymentMethod === 'debit-card' &&
									<Card className='bg-secondary shadow'>
										<CardHeader className='bg-white border-0'>
											<Row className='align-items-center'>
												<Col xs='8' className='d-flex align-items-center'>
													<img
														alt='...'
														width={'60px'}
														height={'40px'}
														src={
															require('../../assets/dashboard_assets/img/icons/cards/debitcard.png')
																.default
														}
													/>{' '}
													<h3 className='mb-0 ml-3'>Debit Card</h3>
												</Col>
											</Row>
										</CardHeader>
										<CardBody>
											<form onSubmit={handleSubmit}>
												<div className='pl-lg-4'>
													<Row>
														<Col lg='12'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-withdrawal-Amount'>
																	Withdrawal Amount
																</label>
																<Field
																	name='withdrawalAmount'
																	type='number'
																	id='input-withdrawal-Amount'
																	placeholder='Withdrawal Amount'
																	className={
																		'form-control-alternative form-control' +
																		(errors.withdrawalAmount &&
																			touched.withdrawalAmount
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='withdrawalAmount'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-debitCard-No'>
																	Debit Card No
																</label>
																<Field
																	id='input-debitCard-No'
																	placeholder='Debit Card No'
																	name='debitCardDebitCardNo'
																	type='text'
																	className={
																		'form-control-alternative form-control' +
																		(errors.debitCardDebitCardNo && touched.debitCardDebitCardNo
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='debitCardDebitCardNo'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
														<Col lg='6'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-card-name'>
																	Name on card
																</label>
																<Field
																	id='input-card-name'
																	placeholder='Enter Name On Card'
																	name='debitCardCardName'
																	className={
																		'form-control-alternative form-control' +
																		(errors.debitCardCardName && touched.debitCardCardName
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='debitCardCardName'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
														<Col lg='4'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-expiry-month'>
																	Expiry Month
																</label>
																<Field
																	id='input-expiry-month'
																	placeholder='Expiry Month'
																	name='debitCardExpiryMonth'
																	type='number'
																	className={
																		'form-control-alternative form-control' +
																		(errors.debitCardExpiryMonth && touched.debitCardExpiryMonth
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='debitCardExpiryMonth'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
														<Col lg='4'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-expiry-year'>
																	Expiry Year
																</label>
																<Field
																	id='input-expiry-year'
																	placeholder='Expiry Year'
																	name='debitCardExpiryYear'
																	type='number'
																	className={
																		'form-control-alternative form-control' +
																		(errors.debitCardExpiryYear && touched.debitCardExpiryYear
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='debitCardExpiryYear'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
														<Col lg='4'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-cvv'>
																	CVV
																</label>
																<Field
																	id='input-cvv'
																	placeholder='CVV'
																	rows='4'
																	name='debitCardCvv'
																	type='number'
																	className={
																		'form-control-alternative form-control' +
																		(errors.debitCardCvv && touched.debitCardCvv
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='debitCardCvv'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
														<Col lg='12'>
															<FormGroup>
																<label
																	className='form-control-label'
																	htmlFor='input-bank-name'>
																	Bank Name
																</label>
																<Field
																	id='input-bank-name'
																	placeholder='Enter Bank Name'
																	name='debitCardBankName'
																	className={
																		'form-control-alternative form-control' +
																		(errors.debitCardBankName && touched.debitCardBankName
																			? ' error-box is-invalid'
																			: '')
																	}
																/>
																<ErrorMessage
																	name='debitCardBankName'
																	component='div'
																	className='is-invalid invalid-feedback'
																/>
															</FormGroup>
														</Col>
													</Row>
												</div>
												<hr className='my-4' />
												<div className='pl-lg-4'>
													<Row>
														<Col md='12' className='text-right'>
															<Link to={'/wallet'}>
																<Button className='mr-3 text-black'>
																	Cancel
																</Button>
															</Link>
															<Button
																type='submit'
																className='text-white btn-info'>
																{buttonName}
															</Button>
														</Col>
													</Row>
												</div>
											</form>
										</CardBody>
									</Card>
								}

								{/* Cash App */}
								{paymentMethod === 'cash-app' && <Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8' className='d-flex align-items-center'>
												<img
													alt='...'
													width={'40px'}
													height={'40px'}
													src={
														require('../../assets/dashboard_assets/img/icons/cards/cashapp.png')
															.default
													}
												/>{' '}
												<h3 className='mb-0 ml-3'>Cash App</h3>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Withdrawal Amount
															</label>
															<Field
																name='withdrawalAmount'
																type='number'
																id='input-withdrawal-Amount'
																placeholder='Withdrawal Amount'
																className={
																	'form-control-alternative form-control' +
																	(errors.withdrawalAmount &&
																		touched.withdrawalAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='withdrawalAmount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-debitCard-No'>
																Cash Card No
															</label>
															<Field
																id='input-debitCard-No'
																placeholder='Cash Card No'
																name='cashAppCashCardNo'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.cashAppCashCardNo && touched.cashAppCashCardNo
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='cashAppCashCardNo'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Name on card
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter Name On Card'
																name='cashAppCardName'
																className={
																	'form-control-alternative form-control' +
																	(errors.cashAppCardName && touched.cashAppCardName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='cashAppCardName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='4'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-expiry-month'>
																Expiry Month
															</label>
															<Field
																id='input-expiry-month'
																placeholder='Expiry Month'
																name='cashAppExpiryMonth'
																type='number'
																className={
																	'form-control-alternative form-control' +
																	(errors.cashAppExpiryMonth && touched.cashAppExpiryMonth
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='cashAppExpiryMonth'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='4'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-expiry-year'>
																Expiry Year
															</label>
															<Field
																id='input-expiry-year'
																placeholder='Expiry Year'
																name='cashAppExpiryYear'
																type='number'
																className={
																	'form-control-alternative form-control' +
																	(errors.cashAppExpiryYear && touched.cashAppExpiryYear
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='cashAppExpiryYear'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='4'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-cvv'>
																CVV
															</label>
															<Field
																id='input-cvv'
																placeholder='CVV'
																rows='4'
																name='cashAppCvv'
																type='number'
																className={
																	'form-control-alternative form-control' +
																	(errors.cashAppCvv && touched.cashAppCvv
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='cashAppCvv'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-bank-name'>
																Cash Tag
															</label>
															<Field
																id='input-bank-name'
																placeholder='Enter Cash Tag'
																name='cashAppCashTag'
																className={
																	'form-control-alternative form-control' +
																	(errors.cashAppCashTag && touched.cashAppCashTag
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='cashAppCashTag'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/wallet'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>}

								{/* Money pak */}
								{paymentMethod === 'money-pak' && <Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8' className='d-flex align-items-center'>
												<img
													alt='...'
													width={'140px'}
													height={'50px'}
													src={
														require('../../assets/dashboard_assets/img/icons/cards/moneypak.png')
															.default
													}
												/>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Withdrawal Amount
															</label>
															<Field
																name='withdrawalAmount'
																type='number'
																id='input-withdrawal-Amount'
																placeholder='Withdrawal Amount'
																className={
																	'form-control-alternative form-control' +
																	(errors.withdrawalAmount &&
																		touched.withdrawalAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='withdrawalAmount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-debitCard-No'>
																Money Pak Number
															</label>
															<Field
																id='input-debitCard-No'
																placeholder='Enter Money Pak Number'
																name='moneyPakNumber'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.moneyPakNumber && touched.moneyPakNumber
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='moneyPakNumber'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																First Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter First Name'
																name='moneyPakFirstName'
																className={
																	'form-control-alternative form-control' +
																	(errors.moneyPakFirstName && touched.moneyPakFirstName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='moneyPakFirstName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Last Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter Last Name'
																name='moneyPakLastName'
																className={
																	'form-control-alternative form-control' +
																	(errors.moneyPakLastName && touched.moneyPakLastName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='moneyPakLastName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/wallet'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>}

								{/* Zelle */}
								{paymentMethod === 'zelle' && <Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8' className='d-flex align-items-center'>
												<img
													alt='...'
													width={'120px'}
													height={'50px'}
													src={
														require('../../assets/dashboard_assets/img/icons/cards/zelle.png')
															.default
													}
												/>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Withdrawal Amount
															</label>
															<Field
																name='withdrawalAmount'
																type='number'
																id='input-withdrawal-Amount'
																placeholder='Withdrawal Amount'
																className={
																	'form-control-alternative form-control' +
																	(errors.withdrawalAmount &&
																		touched.withdrawalAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='withdrawalAmount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-debitCard-No'>
																Registered Phone Number
															</label>
															<Field
																id='input-debitCard-No'
																placeholder='Enter Phone Number'
																name='zellePhoneNo'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.zellePhoneNo && touched.zellePhoneNo
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='zellePhoneNo'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																First Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter First Name'
																name='zelleFirstName'
																className={
																	'form-control-alternative form-control' +
																	(errors.zelleFirstName && touched.zelleFirstName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='zelleFirstName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Last Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter Last Name'
																name='zelleLastName'
																className={
																	'form-control-alternative form-control' +
																	(errors.zelleLastName && touched.zelleLastName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='zelleLastName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Registered E-mail address
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter E-mail address'
																name='zelleEmail'
																className={
																	'form-control-alternative form-control' +
																	(errors.zelleEmail && touched.zelleEmail
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='zelleEmail'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/wallet'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>}

								{/* Google pay */}
								{paymentMethod === 'google-pay' && <Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8' className='d-flex align-items-center'>
												<img
													alt='...'
													width={'150px'}
													height={'70px'}
													src={
														require('../../assets/dashboard_assets/img/icons/cards/googlepay.png')
															.default
													}
												/>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Withdrawal Amount
															</label>
															<Field
																name='withdrawalAmount'
																type='number'
																id='input-withdrawal-Amount'
																placeholder='Withdrawal Amount'
																className={
																	'form-control-alternative form-control' +
																	(errors.withdrawalAmount &&
																		touched.withdrawalAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='withdrawalAmount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-debitCard-No'>
																Registered Phone Number
															</label>
															<Field
																id='input-debitCard-No'
																placeholder='Enter Phone Number'
																name='googlePayPhoneNo'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.googlePayPhoneNo && touched.googlePayPhoneNo
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='googlePayPhoneNo'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																First Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter First Name'
																name='googlePayFirstName'
																className={
																	'form-control-alternative form-control' +
																	(errors.googlePayFirstName && touched.googlePayFirstName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='googlePayFirstName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Last Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter Last Name'
																name='googlePayLastName'
																className={
																	'form-control-alternative form-control' +
																	(errors.googlePayLastName && touched.googlePayLastName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='googlePayLastName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/wallet'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>}

								{/* apple-pay */}
								{paymentMethod === 'apple-pay' && <Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8' className='d-flex align-items-center'>
												<img
													alt='...'
													width={'150px'}
													height={'60px'}
													src={
														require('../../assets/dashboard_assets/img/icons/cards/applepay.png')
															.default
													}
												/>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Withdrawal Amount
															</label>
															<Field
																name='withdrawalAmount'
																type='number'
																id='input-withdrawal-Amount'
																placeholder='Withdrawal Amount'
																className={
																	'form-control-alternative form-control' +
																	(errors.withdrawalAmount &&
																		touched.withdrawalAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='withdrawalAmount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-debitCard-No'>
																Registered Phone Number
															</label>
															<Field
																id='input-debitCard-No'
																placeholder='Enter Phone Number'
																name='applePayPhoneNo'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.applePayPhoneNo && touched.applePayPhoneNo
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='applePayPhoneNo'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																First Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter First Name'
																name='applePayFirstName'
																className={
																	'form-control-alternative form-control' +
																	(errors.applePayFirstName && touched.applePayFirstName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='applePayFirstName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Last Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter Last Name'
																name='applePayLastName'
																className={
																	'form-control-alternative form-control' +
																	(errors.applePayLastName && touched.applePayLastName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='applePayLastName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/wallet'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>}

								{/* paypal */}
								{paymentMethod === 'paypal' && <Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8' className='d-flex align-items-center'>
												<img
													alt='...'
													width={'100px'}
													height={'50px'}
													src={
														require('../../assets/dashboard_assets/img/icons/cards/paypal.png')
															.default
													}
												/>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Withdrawal Amount
															</label>
															<Field
																name='withdrawalAmount'
																type='number'
																id='input-withdrawal-Amount'
																placeholder='Withdrawal Amount'
																className={
																	'form-control-alternative form-control' +
																	(errors.withdrawalAmount &&
																		touched.withdrawalAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='withdrawalAmount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-debitCard-No'>
																Registered Phone Number
															</label>
															<Field
																id='input-debitCard-No'
																placeholder='Enter Phone Number'
																name='paypalPhoneNo'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.paypalPhoneNo && touched.paypalPhoneNo
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='paypalPhoneNo'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																First Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter First Name'
																name='paypalFirstName'
																className={
																	'form-control-alternative form-control' +
																	(errors.paypalFirstName && touched.paypalFirstName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='paypalFirstName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Last Name
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter Last Name'
																name='paypalLastName'
																className={
																	'form-control-alternative form-control' +
																	(errors.paypalLastName && touched.paypalLastName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='paypalLastName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-card-name'>
																Registered E-mail address
															</label>
															<Field
																id='input-card-name'
																placeholder='Enter E-mail address'
																name='paypalEmail'
																className={
																	'form-control-alternative form-control' +
																	(errors.paypalEmail && touched.paypalEmail
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='paypalEmail'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/wallet'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>}
							</Col>
						</Row>
					</Container>
				)}
			/>
		</>
	);
};

export default WalletView;
