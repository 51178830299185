import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Container,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumbs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoanByIdAPI, uploadDocumentByLoanId } from '../../services/api';
import { useHistory } from 'react-router-dom';
import service from 'services/constants';

const DocumentUpload = (props) => {
	const loanID = props.match.params.id;
	const [userID, setUserID] = useState('');
	const [docID, setDocID] = useState('');

	const [frontImg, setFrontImg] = useState(null);
	const [backImg, setBackImg] = useState(null);
	const [addressImg, setAddressImg] = useState(null);
	const [selfieImg, setSelfieImg] = useState(null);

	const frontImageRef = useRef(null);
	const backImageRef = useRef(null);
	const addressImageRef = useRef(null);
	const selfieImageRef = useRef(null);

	const [frontBase64, setFrontBase64] = useState(null);
	const [backBase64, setBackBase64] = useState(null);
	const [addressBase64, setAddressBase64] = useState(null);
	const [selfieBase64, setSelfieBase64] = useState(null);

	const [frontImgStatus, setFrontImgStatus] = useState('2');
	const [backImgStatus, setBackImgStatus] = useState('2');
	const [addressImgStatus, setAddressImgStatus] = useState('2');
	const [selfieImgStatus, setSelfieImgStatus] = useState('2');

	const [error, showError] = useState(false);

	const history = useHistory();

	const getFrontImage = (e) => {
		const file = e.target.files[0];
		const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
		const type = file && e.target.files[0].type;

		if (file.size > 2097152) {
			toast.error('File size exceeds maximum limit 2 MB.');
			e.target.value = '';
			setFrontBase64(null);
			return;
		}
		if (file && !allowedType.includes(type)) {
			toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
			e.target.value = '';
			setFrontBase64(null);
			return;
		}
		displayImage(file, 'front');
		setFrontImg(file);
	};

	const displayImage = (file, string) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			if (string === 'front') {
				setFrontBase64(reader.result);
			} else if (string === 'back') {
				setBackBase64(reader.result);
			} else if (string === 'address') {
				setAddressBase64(reader.result);
			} else {
				setSelfieBase64(reader.result);
			}
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	};

	const getBackImage = (e) => {
		const file = e.target.files[0];
		const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
		const type = file && e.target.files[0].type;

		if (file.size > 2097152) {
			toast.error('File size exceeds maximum limit 2 MB.');
			e.target.value = '';
			setBackBase64(null);
			return;
		}
		if (file && !allowedType.includes(type)) {
			toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
			e.target.value = '';
			setBackBase64(null);
			return;
		}
		displayImage(file, 'back');
		setBackImg(file);
	};

	const getAddressImage = (e) => {
		const file = e.target.files[0];
		const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
		const type = file && e.target.files[0].type;

		if (file.size > 2097152) {
			toast.error('File size exceeds maximum limit 2 MB.');
			e.target.value = '';
			setAddressBase64(null);
			return;
		}
		if (file && !allowedType.includes(type)) {
			toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
			e.target.value = '';
			setAddressBase64(null);
			return;
		}
		displayImage(file, 'address');
		setAddressImg(file);
	};

	const getSelfieImage = (e) => {
		const file = e.target.files[0];
		const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
		const type = file && e.target.files[0].type;

		if (file.size > 2097152) {
			toast.error('File size exceeds maximum limit 2 MB.');
			e.target.value = '';
			setSelfieBase64(null);
			return;
		}
		if (file && !allowedType.includes(type)) {
			toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
			e.target.value = '';
			setSelfieBase64(null);
			return;
		}
		displayImage(file, 'selfie');
		setSelfieImg(file);
	};

	let addLoan = (e) => {
		e.preventDefault();
		showError(true);
		if (frontBase64 && backBase64 && addressBase64 && selfieBase64) {
			let loanAdd = {
				userId: userID,
				loanId: loanID,
				docId: docID,
				frontLicenceStatus: (frontImgStatus === "0" || frontImgStatus === null) ? "2": frontImgStatus,
				backLicenceStatus: (backImgStatus === "0" || backImgStatus === null) ? "2": backImgStatus,
				addressProofStatus: (addressImgStatus === "0" || addressImgStatus === null) ? "2": addressImgStatus,
				selfieStatus: (selfieImgStatus === "0" || selfieImgStatus === null) ? "2": selfieImgStatus,
			};

			const formData = new FormData();
			formData.append('data', JSON.stringify(loanAdd));
			formData.append('licenceFront', frontImg);
			formData.append('licenceBack', backImg);
			formData.append('addressProof', addressImg);
			formData.append('selfie', selfieImg);

			uploadDocumentByLoanId(formData).then((result) => {
				if (result.result) {
					toast.success('Document uploaded successfully.');
					history.push(
						`/application/document-under-process/${loanID}`
					);
				} else {
					toast.error('Something went wrong.');
				}
			});
		}
	};

	const onButtonClickFront = () => {
		frontImageRef.current.click();
	};
	const onButtonClickBack = () => {
		backImageRef.current.click();
	};
	const onButtonClickAddress = () => {
		addressImageRef.current.click();
	};
	const onButtonClickSelfie = () => {
		selfieImageRef.current.click();
	};

	useEffect(() => {
		getLoanByIdAPI(loanID).then(loan => {
			console.log('npm install nodemailer:::>>',loan);
			if (loan?.length > 0) {
				setUserID(loan[0]['userId']);
				setDocID(loan[0]['docId']);

				setFrontBase64(loan[0] && loan[0]['frontLicence'] && service.API_URL + '/' + loan[0]['frontLicence']);
				setBackBase64(loan[0] && loan[0]['backLicence'] && service.API_URL + '/' + loan[0]['backLicence']);
				setAddressBase64(loan[0] && loan[0]['addressProof'] && service.API_URL + '/' + loan[0]['addressProof']);
				setSelfieBase64(loan[0] && loan[0]['selfie'] && service.API_URL + '/' + loan[0]['selfie']);

				setFrontImgStatus(loan[0] && loan[0]['frontLicenceStatus']);
				setBackImgStatus(loan[0] && loan[0]['backLicenceStatus']);
				setAddressImgStatus(loan[0] && loan[0]['addressProofStatus']);
				setSelfieImgStatus(loan[0] && loan[0]['selfieStatus']);
			}
		});
	}, [loanID]);

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
			<Breadcrumbs breadcrumbName='Document Upload' />
			{/* Page content */}
			<Container className='mt--7' fluid>
				<Row>
					<Col className='order-xl-1' xl='12'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-white border-0'>
								<Row className='align-items-center'>
									<Col xs='8'>
										<h2 className='mb-0'>Verify Yourself</h2>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<form onSubmit={(e) => addLoan(e)}>
									<div className='row'>
										<div className='col-md-6 col-lg-6 col-sm-12 col-xl-6 my-auto'>
											<h3 className='text-green mb-4'>
												The Verification demonstrates to other users that you
												are a "true" person who lives in a "real" home, all of
												which have been checked by EASY LOAN AMERICA. You will
												now be known as a reliable participant, which will
												increase the chances of receiving loans.
												<br />
												<br />
												Upload your driver's licence (front and back), proof of
												address, and a selfie to verify your identity.
											</h3>
										</div>
										<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
											<img
												src={
													require('../../assets/dashboard_assets/img/brand/document-upload/verify-yourself.png')
														.default
												}
												className='img-fluid'
												alt=''
											/>
										</div>
									</div>

									<h1 className='font-weight-bold text-green mb-4'>
										Quick Tips for Successful Verification
									</h1>
									<div className='container'>
										{/* Identity card */}
										<div className='row'>
											<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12'>
												<h3 className='font-weight-bold text-green mb-4'>
													Identity Card
												</h3>
											</div>
											<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
												<div className='row'>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/identity-card-good-example.png')
																	.default
															}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<div>
															<h3 className='text-success'>
																<i className='fas fa-check mr-2'></i>
																Good example
															</h3>
															<h5 className='text-muted'>
																Full name, date of birth,
																<br />
																and ID number are all
																<br />
																clearly visible in the picture.
															</h5>
														</div>
													</div>
													<div className='mt-5'></div>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/identity-card-blurry-photo.png')
																	.default
															}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<div>
															<h3 className='text-danger'>
																<i className='fas fa-times mr-2'></i>
																Blurry Photo
															</h3>
															<h5 className='text-muted'>
																Maintain a firm grip on
																<br />
																your ID so that the information
																<br />
																is legible.
															</h5>
														</div>
													</div>
												</div>
											</div>

											<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
												<div className='row'>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/identity-card-cut-off-photo.png')
																	.default
															}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<div>
															<h3 className='text-danger'>
																<i className='fas fa-times mr-2'></i>
																Cut-off Photo
															</h3>
															<h5 className='text-muted'>
																Make sure your ID is in
																<br />
																frame on all sides.
															</h5>
														</div>
													</div>
													<div className='mt-5'></div>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/identity-card-bad-perpective.png')
																	.default
															}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
														<div>
															<h3 className='text-danger'>
																<i className='fas fa-times mr-2'></i>
																Bad Perspective
															</h3>
															<h5 className='text-muted'>
																Make sure you just press the
																<br />
																picture from the top view.
															</h5>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='my-5'></div>

										{/* selfie */}
										<div className='row'>
											<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12'>
												<h3 className='font-weight-bold text-green mb-4'>
													Selfie
												</h3>
											</div>
											<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
												<div className='row'>
													<div className='col-md-4 col-lg-4 col-xl-4 col-sm-12'>
														<img
															style={{ height: '200px' }}
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/selfie-good-example.png')
																	.default
															}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-8 col-lg-8 col-xl-8 col-sm-12'>
														<div>
															<h3 className='text-success'>
																<i className='fas fa-check mr-2'></i>
																Good example
															</h3>
															<h5 className='text-muted'>
																Fully in frame, with
																<br />
																clear face. With front
																<br />
																view and on eye level.
															</h5>
														</div>
													</div>
													<div className='mt-5'></div>
													<div className='col-md-4 col-lg-4 col-xl-4 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/selfie-blury-photo.png')
																	.default
															}
															style={{ height: '200px' }}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-8 col-lg-8 col-xl-8 col-sm-12'>
														<div>
															<h3 className='text-danger'>
																<i className='fas fa-times mr-2'></i>
																Blurry Photo
															</h3>
															<h5 className='text-muted'>
																Avoid Blue Selfies.
															</h5>
														</div>
													</div>
												</div>
											</div>

											<div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
												<div className='row'>
													<div className='col-md-4 col-lg-4 col-xl-4 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/selfie-cut-off-photo.png')
																	.default
															}
															style={{ height: '200px' }}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-8 col-lg-8 col-xl-8 col-sm-12'>
														<div>
															<h3 className='text-danger'>
																<i className='fas fa-times mr-2'></i>
																Cut-off Photo
															</h3>
															<h5 className='text-muted'>
																Make sure your face is
																<br />
																properly in the frame.
															</h5>
														</div>
													</div>
													<div className='mt-5'></div>
													<div className='col-md-4 col-lg-4 col-xl-4 col-sm-12'>
														<img
															src={
																require('../../assets/dashboard_assets/img/brand/document-upload/selfie-bad-perspective.png')
																	.default
															}
															style={{ height: '200px' }}
															className='img-fluid'
															alt=''
														/>
													</div>
													<div className='col-md-8 col-lg-8 col-xl-8 col-sm-12'>
														<div>
															<h3 className='text-danger'>
																<i className='fas fa-times mr-2'></i>
																Bad Perspective
															</h3>
															<h5 className='text-muted'>
																Make sure to take the
																<br />
																selfie from front and
																<br />
																on eye level only.
															</h5>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='my-5'></div>

									<h1 className='font-weight-bold text-green mb-4'>
										Upload Documents Here
									</h1>
									<div className='row'>
										<div className='pl-lg-4'>
											<Row className='mx-4'>
												<Col lg='5' sm='12'>
													<div
														className={`${error && !frontBase64 && 'error-border'
															} row upload-img-border`}>
														{(frontImgStatus !== "2") && <div style={{ position: 'absolute', top: '7px', right: '15px' }}>
															{frontImgStatus === '2' ? (
																<Badge
																	className='bg-yellow text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Under process
																</Badge>
															) : frontImgStatus === '1' ? (
																<Badge
																	className='bg-success text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Approved
																</Badge>
															) : (
																<Badge
																	className='bg-danger text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Rejected
																</Badge>
															)}
														</div>}
														<div className='mt-5 col-md-12 col-lg-12 col-xl-12 col-sm-12 text-center' style={(frontImgStatus === "1") ? {paddingBottom: '110px'} : {}}>
															<img
																style={{ height: '130px', width: '180px' }}
																src={
																	frontBase64
																		? frontBase64
																		: require('../../assets/dashboard_assets/img/brand/document-upload/upload-identity-card-front.png')
																			.default
																}
																className='img-fluid'
																alt=''
															/>
														</div>
														{(frontImgStatus === null || frontImgStatus === "2" || frontImgStatus === "0") && (
															<>
																<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 mt-3 text-center'>
																	<h3 className='text-green'>
																		Upload Identity Card(Front)
																	</h3>
																</div>
																<div className='col-md-12 text-center mt-1 mb-3'>
																	<Button
																		onClick={() => onButtonClickFront()}
																		className='text-white bg-green'>
																			{frontImgStatus === '0' ? 'Re-upload' : 'Browse'}
																	</Button>
																</div>
															</>
														)}
													</div>
													{error && !frontBase64 && (
														<div className='is-invalid invalid-feedback text-center font-weight-bold'>
															This field is required
														</div>
													)}
													<FormGroup style={{ visibility: 'hidden' }}>
														<div className='custom-file'>
															<input
																ref={frontImageRef}
																name='licenceFront'
																type='file'
																className='custom-file-input file-input'
																id='customFileLang'
																lang='en'
																onChange={(e) => getFrontImage(e)}
															/>
															<label
																className='custom-file-label'
																htmlFor='customFileLang'></label>
														</div>
													</FormGroup>
												</Col>

												<Col lg='5' md='5' sm='12' className='ml-9'>
													<div
														className={`${error && !backBase64 && 'error-border'
															} row upload-img-border`}>
														{(backImgStatus !== "2") && <div style={{position: 'absolute', top: '7px', right: '15px'}}>
															{backImgStatus === '2' ? (
																<Badge
																	className='bg-yellow text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Under process
																</Badge>
															) : backImgStatus === '1' ? (
																<Badge
																	className='bg-success text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Approved
																</Badge>
															) : (
																<Badge
																	className='bg-danger text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Rejected
																</Badge>
															)}
														</div>}
														<div className='mt-5 col-md-12 col-lg-12 col-xl-12 col-sm-12 text-center' style={(backImgStatus === "1") ? {paddingBottom: '110px'} : {}}>
															<img
																style={{ height: '130px', width: '180px' }}
																src={
																	backBase64
																		? backBase64
																		: require('../../assets/dashboard_assets/img/brand/document-upload/upload-identity-card-back.png')
																			.default
																}
																className='img-fluid'
																alt=''
															/>
														</div>
														{(backImgStatus === null || backImgStatus === "2" || backImgStatus === "0") && (
															<>
																<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 mt-3 text-center'>
																	<h3 className='text-green'>
																		Upload Identity Card(Back)
																	</h3>
																</div>
																<div className='col-md-12 text-center mt-1 mb-3'>
																	<Button
																		onClick={() => onButtonClickBack()}
																		className='text-white bg-green'>
																		{backImgStatus === '0' ? 'Re-upload' : 'Browse'}
																	</Button>
																</div>
															</>
														)}
													</div>
													{error && !backBase64 && (
														<div className='is-invalid invalid-feedback text-center font-weight-bold'>
															This field is required
														</div>
													)}
													<FormGroup style={{ visibility: 'hidden' }}>
														<div className='custom-file'>
															<input
																ref={backImageRef}
																name='licenceBack'
																type='file'
																className='custom-file-input file-input'
																id='customFileLang'
																lang='en'
																onChange={(e) => getBackImage(e)}
															/>
															<label
																className='custom-file-label'
																htmlFor='customFileLang'></label>
														</div>
													</FormGroup>
												</Col>

												<div className='mt-4'></div>

												<Col lg='5' md='5' sm='12'>
													<div
														className={`${error && !addressBase64 && 'error-border'
															} row upload-img-border`}>
														{(addressImgStatus !== "2") && <div style={{position: 'absolute', top: '7px', right: '15px'}}>
															{addressImgStatus === '2' ? (
																<Badge
																	className='bg-yellow text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Under process
																</Badge>
															) : addressImgStatus === '1' ? (
																<Badge
																	className='bg-success text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Approved
																</Badge>
															) : (
																<Badge
																	className='bg-danger text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Rejected
																</Badge>
															)}
														</div>}
														<div className='mt-5 col-md-12 col-lg-12 col-xl-12 col-sm-12 text-center' style={(addressImgStatus === "1") ? {paddingBottom: '110px'} : {}}>
															<img
																style={{ height: '130px', width: '180px' }}
																src={
																	addressBase64
																		? addressBase64
																		: require('../../assets/dashboard_assets/img/brand/document-upload/upload-address-proof.png')
																			.default
																}
																className='img-fluid'
																alt=''
															/>
														</div>
														{(addressImgStatus === null || addressImgStatus === "2" || addressImgStatus === "0") && (
															<>
																<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 mt-3 text-center'>
																	<h3 className='text-green'>
																		Upload Proof of Address
																	</h3>
																</div>
																<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 text-center mt-1 mb-3'>
																	<Button
																		onClick={() => onButtonClickAddress()}
																		className='text-white bg-green'>
																			{addressImgStatus === '0' ? 'Re-upload' : 'Browse'}
																	</Button>
																</div>
															</>
														)}
													</div>
													{error && !addressBase64 && (
														<div className='is-invalid invalid-feedback text-center font-weight-bold'>
															This field is required
														</div>
													)}
													<FormGroup style={{ visibility: 'hidden' }}>
														<div className='custom-file'>
															<input
																ref={addressImageRef}
																name='addressProof'
																type='file'
																className='custom-file-input file-input'
																id='customFileLang'
																lang='en'
																onChange={(e) => getAddressImage(e)}
															/>
															<label
																className='custom-file-label'
																htmlFor='customFileLang'></label>
														</div>
													</FormGroup>
												</Col>

												<Col lg='5' sm='12' md='5' className='ml-9'>
													<div
														className={`${error && !selfieBase64 && 'error-border'
															} row upload-img-border`}>
														{(selfieImgStatus !== "2") && <div style={{position: 'absolute', top: '7px', right: '15px'}}>
															{selfieImgStatus === '2' ? (
																<Badge
																	className='bg-yellow text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Under process
																</Badge>
															) : selfieImgStatus === '1' ? (
																<Badge
																	className='bg-success text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Approved
																</Badge>
															) : (
																<Badge
																	className='bg-danger text-white badge-default'
																	onClick={(e) => e.preventDefault()}>
																	Rejected
																</Badge>
															)}
														</div>}
														<div className='mt-5 col-md-12 col-lg-12 col-xl-12 col-sm-12 text-center' style={(selfieImgStatus === "1") ? {paddingBottom: '110px'} : {}}>
															<img
																style={{
																	height: '130px',
																	width: '180px',
																}}
																src={
																	selfieBase64
																		? selfieBase64
																		: require('../../assets/dashboard_assets/img/brand/document-upload/upload-your-selfie.png')
																			.default
																}
																className='img-fluid'
																alt=''
															/>
														</div>
														{(selfieImgStatus === null || selfieImgStatus === '2' || selfieImgStatus === "0") && (
															<>
																<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 mt-3 text-center'>
																	<h3 className='text-green'>Upload Your Selfie</h3>
																</div>
																<div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 text-center mt-1 mb-3'>
																	<Button
																		onClick={() => onButtonClickSelfie()}
																		className='text-white bg-green'>
																			{selfieImgStatus === '0' ? 'Re-upload' : 'Browse'}
																	</Button>
																</div>
															</>
														)}
													</div>
													{error && !selfieBase64 && (
														<div className='is-invalid invalid-feedback text-center font-weight-bold'>
															This field is required
														</div>
													)}
													<FormGroup style={{ visibility: 'hidden' }}>
														<div className='custom-file'>
															<input
																ref={selfieImageRef}
																name='selfie'
																type='file'
																className='custom-file-input file-input'
																id='customFileLang'
																lang='en'
																onChange={(e) => getSelfieImage(e)}
															/>
															<label
																className='custom-file-label'
																htmlFor='customFileLang'></label>
														</div>
													</FormGroup>
												</Col>
											</Row>
										</div>
									</div>
									<hr className='my-4' />

									<div className='pl-lg-4'>
										<Row>
											<Col md='12' className='text-right'>
												<Link to={'/application'}>
													<Button className='mr-3 text-black'>Cancel</Button>
												</Link>
												<Button type='submit' className='text-white btn-info'>
													Continue
												</Button>
											</Col>
										</Row>
									</div>
								</form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default DocumentUpload;
