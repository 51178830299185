import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardTitle,
	Container,
	Row,
	Col,
	Modal,
	Button,
} from 'reactstrap';
import Tables from '../../components/Tables';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getDashboardDataApi } from 'services/api';
import NumberFormat from 'react-number-format';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { getWalletByUseridAPI } from '../../services/api';
import { toast } from 'react-toastify';
import { Badge } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

const WalletList = () => {
	const [dashboardData, setDashboardData] = useState(null);
	const [walletData, setWalletData] = useState([]);
	const [amountData, setAmount] = useState(0);
	const [withdrawlAmount, setTotalWithdrawlAmount] = useState(0);
	const [remainingWallet, setRemainingWallet] = useState(0);
	const [show, setShow] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const history = useHistory();

	const transferClick = (e) => {
		e.preventDefault();
		setShow(true);
		CanChange();
	};

	const goToWallet = (paymentMethod = '') => {
		if (withdrawlAmount == dashboardData.totalLoanAmountOfUser) {
			setShow(true);
			CanChange();
		} else {
			const tokenData = jwtDecode(getToken());
			getWalletByUseridAPI(tokenData.user.id).then((user) => {
				setWalletData(user);
			});
			history.push(`/wallet/` + tokenData.user.id + '/' + paymentMethod);
		}
	};
	const data = walletData;
	const CanChange = () => {
		return (
			<>
				<SweetAlert
					warning
					confirmBtnBsStyle='success'
					style={{ fontSize: '10px' }}
					title='Sorry!! an ERROR occured while processing the transfer. Please Contact Easy Loan America for further assistance'
					show={show}
					onConfirm={() => setShow(false)}></SweetAlert>
			</>
		);
	};

	const columns = [
		{ dataField: 'id', text: 'id', sort: true, hidden: true },
		{ dataField: 'walletId', text: 'walletid', sort: true, hidden: true },
		{
			dataField: 'createdAt',
			text: 'Wallet Added Date',
			sort: true,
			formatter: (cell, row) => (
				<>
					{row.createdAt
						? moment.utc(row.createdAt).format('DD-MM-YYYY')
						: 'N/A'}
				</>
			),
		},
		{
			dataField: 'paymentMethod',
			text: 'Payment/Transaction Method',
			sort: true,
		},
		{ dataField: 'withdrawalAmount', text: 'Withdraw Amount', sort: true },
		{
			dataField: 'remarks',
			text: 'Remarks',
			sort: true,
			formatter: (cell, row) => <>{row?.remarks ? row.remarks : 'N/A'}</>,
		},
		{
			dataField: 'walletStatus',
			text: 'Status',
			sort: true,
			formatter: (cell, row) => (
				<>
					{row.walletStatus === '2' ? (
						<Badge
							className='bg-yellow text-white badge-default'
							onClick={(e) => e.preventDefault()}
							title='Please wait for at least 24 to 48 hours'>
							Pending
						</Badge>
					) : row.walletStatus === '1' ? (
						<Badge
							className='bg-success text-white badge-default'
							onClick={(e) => e.preventDefault()}>
							Approved
						</Badge>
					) : (
						<Badge
							className='bg-danger text-white badge-default'
							onClick={(e) => e.preventDefault()}>
							Unapproved
						</Badge>
					)}
				</>
			),
		},
	];

	const getAllWalletByUserId = () => {
		const tokenData = jwtDecode(getToken());
		getWalletByUseridAPI(tokenData.user.id).then((user) => {
			setWalletData(user);
			if (user.length > 0) {
				setAmount(user[0].withdrawalAmount);
			}
		});
	};

	useEffect(() => {
		const tokenData = jwtDecode(getToken());
		getDashboardDataApi({ userId: tokenData.user.id }).then((result) => {
			if (result) {
				setTotalWithdrawlAmount(result.totalWithdrawlAmountOfUser);
				setDashboardData(result);
				setRemainingWallet(
					result.totalLoanAmountOfUser - result.totalWithdrawlAmountOfUser
				);
			}
		});
		getAllWalletByUserId();
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='Wallet Transaction Management' />
			<div className='header bg-custom pb-2'>
				<Container fluid>
					<div className='header-body'>
						<Row className='mt-4'>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Wallet Balance
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={remainingWallet}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Transferred Amount
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>0</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Withdrawal Amount
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={withdrawlAmount}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</Container>
				<Container fluid>
					<div className='header-body'>
						<Row className='mt-4'>
							<Col lg='6' xl='3'></Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody
										style={{ background: 'white', borderRadius: '5px' }}>
										<Row>
											<div className='col'>
												<CardTitle
													style={{ color: '#117b4a' }}
													tag='h5'
													className='text-uppercase text-center mb-0'>
													<a type='button' onClick={(e) => transferClick(e)}>
														Transfer
													</a>
												</CardTitle>
											</div>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody
										style={{ background: 'white', borderRadius: '5px' }}>
										<Row>
											<div className='col'>
												<CardTitle
													style={{ color: '#117b4a' }}
													tag='h5'
													className='text-uppercase text-center mb-0'>
													<a type='button' onClick={() => setShowModal(true)}>
														Withdraw
													</a>
												</CardTitle>
											</div>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='3'></Col>
						</Row>
						<div style={{ marginTop: '10%' }}></div>
					</div>
				</Container>
			</div>
			<CanChange />
			<Modal
				className='modal-dialog-centered'
				style={{ maxHeight: '600px' }}
				isOpen={showModal}>
				<div className='modal-header py-2'>
					<button
						aria-label='Close'
						className='close'
						data-dismiss='modal'
						type='button'
						onClick={() => setShowModal(false)}>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className='modal-body'>
					<Row>
						<Col
							lg='12'
							onClick={() => goToWallet('debit-card')}
							className='mb-3 d-flex justify-content-center cursor-pointer'>
							<div>
								<img
									alt='...'
									width={'60px'}
									height={'40px'}
									src={
										require('../../assets/dashboard_assets/img/icons/cards/debitcard.png')
											.default
									}
								/>{' '}
								Debit Card
							</div>
						</Col>
						<Col
							lg='12'
							onClick={() => goToWallet('cash-app')}
							className='mb-3 d-flex justify-content-center cursor-pointer'>
							<div>
								<img
									alt='...'
									width={'40px'}
									height={'40px'}
									src={
										require('../../assets/dashboard_assets/img/icons/cards/cashapp.png')
											.default
									}
								/>{' '}
								Cash App
							</div>
						</Col>
						<Col
							lg='12'
							onClick={() => goToWallet('money-pak')}
							className='mb-3 d-flex justify-content-center cursor-pointer'>
							<img
								alt='...'
								width={'140px'}
								height={'50px'}
								src={
									require('../../assets/dashboard_assets/img/icons/cards/moneypak.png')
										.default
								}
							/>
						</Col>
						<Col
							lg='12'
							onClick={() => goToWallet('zelle')}
							className='mb-3 d-flex justify-content-center cursor-pointer'>
							<img
								alt='...'
								width={'120px'}
								height={'50px'}
								src={
									require('../../assets/dashboard_assets/img/icons/cards/zelle.png')
										.default
								}
							/>
						</Col>
						<Col
							lg='12'
							onClick={() => goToWallet('google-pay')}
							className='mb-3 d-flex justify-content-center cursor-pointer'>
							<img
								alt='...'
								width={'150px'}
								height={'70px'}
								src={
									require('../../assets/dashboard_assets/img/icons/cards/googlepay.png')
										.default
								}
							/>
						</Col>
						<Col
							lg='12'
							onClick={() => goToWallet('apple-pay')}
							className='mb-3 d-flex justify-content-center cursor-pointer'>
							<img
								alt='...'
								width={'180px'}
								height={'70px'}
								src={
									require('../../assets/dashboard_assets/img/icons/cards/applepay.png')
										.default
								}
							/>
						</Col>
						<Col
							lg='12'
							onClick={() => goToWallet('paypal')}
							className='d-flex justify-content-center cursor-pointer'>
							<img
								alt='...'
								width={'100px'}
								height={'50px'}
								src={
									require('../../assets/dashboard_assets/img/icons/cards/paypal.png')
										.default
								}
							/>
						</Col>
					</Row>
				</div>
			</Modal>
			<Tables
				data={data}
				columns={columns}
				defaultSortKey='createdAt'
				defaultSortDirection='desc'
				currentPath='wallet'
				tableTitle='Wallet Transaction'
			/>
		</>
	);
};

export default WalletList;
