import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AdminLayout from './layouts/Admin.js';
import AuthLayout from './layouts/Auth.js';
import ProtectedRoutes from './ProtectedRoutes.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

ReactDOM.render(
    // <BrowserRouter>
    <BrowserRouter basename='dashboard/#'>
        <Switch>
            <Route path="/login" component={AuthLayout} />
            <Route path="/signup" component={AuthLayout} />
            <Route path="/forgot-password" component={AuthLayout} />
            <Route path="/reset-password" component={AuthLayout} />
            <ProtectedRoutes path="/" component={AdminLayout} />
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);
