import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import service from 'services/constants';
// reactstrap components
import { Card, CardHeader, CardBody, FormGroup, Container, Row, Col, Button } from 'reactstrap';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';
import Breadcrumbs from '../../components/Breadcrumbs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllUserAPI, getLoanByIdAPI, getBankDetailsByIdAPI, updateLoanAPI } from '../../services/api';
import { useHistory } from 'react-router-dom';

const LoanView = (props) => {
	const [titleName, setTitle] = useState('Application');
	const [buttonName, setButton] = useState('Continue');
	const [userDetail, setUserDetails] = useState([]);
	const [loanDetail, setLoanDetails] = useState([]);
	const tokenData = jwtDecode(getToken());
	const [selectedUserDetail, setSelectedUserDetails] = useState({});
	const [bankRelated, bankStatusData] = useState();
	const [frontBase64, setFrontBase64] = useState(null);
	const [backBase64, setBackBase64] = useState(null);
	const [addressBase64, setAddressBase64] = useState(null);
	const [selfieBase64, setSelfieBase64] = useState(null);

	const [pastLoanValue, setPastLoanValue] = useState(null);
	const [pendingLoanValue, setPendingLoanValue] = useState(null);
	const [pendingBillsValue, setPendingBillsValue] = useState(null);
	const [setaccountType, setAccountType] = useState(null);
	const [setloanType, setLoanType] = useState(null);
	const [setmonths, setMonths] = useState(null);
	const [setresidingYear, setResidingYear] = useState(null);
	const [show, setShow] = useState(false);
	const [statusObj, setStatusObj] = useState(false);
	const history = useHistory();

	const [isTCAccept, setIsTCAccept] = useState(false);

	const [typeOfAccount, BankAccount] = useState();

	const onHandleChange = (e) => {
		if (e.target.name === 'isPastLoan') {
			setPastLoanValue(e.target.value);
		}
		if (e.target.name === 'isPendingLoan') {
			setPendingLoanValue(e.target.value);
		}
		if (e.target.name === 'isPendingBills') {
			setPendingBillsValue(e.target.value);
		}
		if (e.target.name === 'accountType') {
			setAccountType(e.target.value);
		}
		if (e.target.name === 'loanType') {
			setLoanType(e.target.value);
		}
		if (e.target.name === 'months') {
			setMonths(e.target.value);
		}
		if (e.target.name === 'residingYear') {
			setResidingYear(e.target.value);
		}
		if (e.target.name === 'email') {
			const userID = e.target.value;
			const user = userDetail.filter((item) => item.id === userID);
			if (user && user.length > 0) {
				setSelectedUserDetails({
					userId: user[0].id,
					firstName: user[0].firstName,
					lastName: user[0].lastName,
					middleName: user[0].middleName,
					phoneNo1: user[0].phoneNo1,
					address1: user[0].address1,
				});
			}
		}
	};

	var updateLoan = (data) => {
		if (isTCAccept) {
			if (data) {
				if (
					data.addressProofStatus === '2' &&
					data.addressProof === null &&
					data.backLicenceStatus === '2' &&
					data.backLicence === null &&
					data.frontLicenceStatus === '2' &&
					data.frontLicence === null &&
					data.selfieStatus === '2' &&
					data.selfie === null
				) {
					history.push(`/application/document-upload/${data.id}`);
				}

				if (
					data.addressProofStatus === '2' &&
					data.addressProof &&
					data.backLicenceStatus === '2' &&
					data.backLicence &&
					data.frontLicenceStatus === '2' &&
					data.frontLicence &&
					data.selfieStatus === '2' &&
					data.selfie
				) {
					history.push(`/application/document-under-process/${data.id}`, { remarks: data?.rejectReason });
				}

				if (
					data.frontLicenceStatus === '1' &&
					data.backLicenceStatus === '1' &&
					data.addressProofStatus === '1' &&
					data.selfieStatus === '1'
				) {
					history.push(`/application/document-approved/${data.id}`, { remarks: data?.rejectReason });
				}

				if (
					data.frontLicenceStatus === '0' ||
					data.backLicenceStatus === '0' ||
					data.addressProofStatus === '0' ||
					data.selfieStatus === '0'
				) {
					history.push(`/application/document-rejected/${data.id}`);
				}
			}

			if (bankRelated.bankStatus == '2') {
				history.push(`/application/bank-under-process/${data.id}`);
			}
			if (bankRelated.bankStatus == '0') {
				history.push(`/application/bank-reject-process/${data.id}`);
			}
			if (bankRelated.bankStatus == '1') {
				history.push(`/application/bank-verified-process/${data.id}`);
			}
			return;
		}
	};

	const downloadImage = (e, image) => {
		e.preventDefault();
		const link = document.createElement('a');
		link.href = `${image}`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	useEffect(() => {
		getAllUserAPI().then((user) => {
			setUserDetails(user);
		});
		if (!props.match.path.endsWith('create')) {
			getLoanByIdAPI(props.match.params.id).then((loan) => {
				setLoanDetails(loan && loan[0]);
				setFrontBase64(loan && loan[0] && loan[0]['frontLicence'] && service.API_URL + '/' + loan[0]['frontLicence']);
				setBackBase64(loan && loan[0] && loan[0]['backLicence'] && service.API_URL + '/' + loan[0]['backLicence']);
				setAddressBase64(loan && loan[0] && loan[0]['addressProof'] && service.API_URL + '/' + loan[0]['addressProof']);
				setSelfieBase64(loan && loan[0] && loan[0]['selfie'] && service.API_URL + '/' + loan[0]['selfie']);
				BankAccount(
					loanDetail.accountType === 'saving'
						? 'Saving Account'
						: loanDetail.accountTyp === 'checking'
							? 'Checking Account'
							: 'Virtual Account'
				);
			});
			getBankDetailsByIdAPI(props.match.params.id).then((data) => {
				bankStatusData(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAcceptTc = (e) => {
		setIsTCAccept(e.target.checked);
	};

	useEffect(() => {
		const updateTcStatus = async () => {
			if (loanDetail?.tcStatus === "0") {
				const formData = new FormData();
				formData.append("data", JSON.stringify({ ...loanDetail, tcStatus: '1' }));
				await updateLoanAPI(props.match.params.id, formData, true)
			}
		}
		updateTcStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTCAccept])


	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
			<Breadcrumbs breadcrumbName="Loan Application View" />
			{/* Page content */}
			<Formik
				onSubmit={(values, actions) => {
					updateLoan(values);
				}}
				initialValues={loanDetail}
				enableReinitialize
				render={({ handleSubmit, errors, touched }) => (
					<Container className="mt--7" fluid>
						<Row>
							<Col className="order-xl-1" xl="12">
								<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<Row className="align-items-center">
											<Col xs="8">
												<h3 className="mb-0">{titleName}</h3>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<h6 className="heading-small text-muted mb-4">Contact Details</h6>
											<div className="pl-lg-4">
												<Row>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-first-name">
																First name
															</label>
															<Field
																readOnly={true}
																name="firstName"
																type="text"
																id="input-first-name"
																placeholder="First name"
																className={
																	'form-control-alternative form-control' +
																	(errors.firstName && touched.firstName ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="firstName"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-middle-name">
																Middle Name
															</label>
															<Field
																readOnly={true}
																id="input-middle-name"
																placeholder="Middle Name"
																name="middleName"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.middleName && touched.middleName ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="middleName"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-last-name">
																Last name
															</label>
															<Field
																readOnly={true}
																id="input-last-name"
																placeholder="Last name"
																name="lastName"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.lastName && touched.lastName ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="lastName"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														{!props.match.path.endsWith('create') && (
															<FormGroup>
																<label className="form-control-label" htmlFor="input-email">
																	Email
																</label>
																<Field
																	readOnly={true}
																	name="email"
																	className={
																		'form-control-alternative form-control' +
																		(errors.email && touched.email ? ' error-box is-invalid' : '')
																	}
																/>
																<ErrorMessage
																	name="email"
																	component="div"
																	className="is-invalid invalid-feedback"
																/>
															</FormGroup>
														)}
														{props.match.path.endsWith('create') && (
															<FormGroup>
																<label className="form-control-label" htmlFor="input-email">
																	Email
																</label>
																<Field
																	readOnly={true}
																	as="select"
																	name="email"
																	className={
																		'form-control-alternative form-control' +
																		(errors.email && touched.email ? ' error-box is-invalid' : '')
																	}
																>
																	{(props) => (
																		<React.Fragment>
																			<select
																				name="email"
																				className={
																					'form-control-alternative form-control' +
																					(errors.email && touched.email
																						? ' error-box is-invalid'
																						: '')
																				}
																				onChange={onHandleChange}
																			>
																				<option value="">Select Email Address</option>
																				{userDetail.map((e, key) => {
																					return (
																						<option key={key} value={e.id}>
																							{e.email}
																						</option>
																					);
																				})}
																			</select>
																		</React.Fragment>
																	)}
																</Field>
																<ErrorMessage
																	name="email"
																	component="div"
																	className="is-invalid invalid-feedback"
																/>
															</FormGroup>
														)}
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-phone-number">
																Phone Number
															</label>
															<Field
																readOnly={true}
																id="input-phone-number"
																placeholder="9874563210"
																name="phoneNo1"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.phoneNo1 && touched.phoneNo1 ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="phoneNo1"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-phone-number">
																Home Phone Number
															</label>
															<Field
																readOnly={true}
																id="input-phone-number"
																placeholder="9874563210"
																name="phoneNo2"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.phoneNo2 && touched.phoneNo2 ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="phoneNo2"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-email">
																Address Line 1
															</label>
															<Field
																readOnly={true}
																as="textarea"
																placeholder="Address"
																rows="4"
																name="address1"
																className={
																	'form-control-alternative form-control' +
																	(errors.address1 && touched.address1 ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="address1"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-email">
																Address Line 2
															</label>
															<Field
																readOnly={true}
																as="textarea"
																placeholder="Address"
																rows="4"
																name="address2"
																className={
																	'form-control-alternative form-control' +
																	(errors.address2 && touched.address2 ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="address2"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-city">
																City
															</label>
															<Field
																readOnly={true}
																id="input-city"
																placeholder="City"
																name="city"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.city && touched.city ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="city"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-state">
																State
															</label>
															<Field
																readOnly={true}
																id="input-state"
																placeholder="State"
																name="state"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.state && touched.state ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="state"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-pinCode">
																Zip code
															</label>
															<Field
																readOnly={true}
																id="input-pinCode"
																placeholder="Zip code"
																name="pinCode"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.pinCode && touched.pinCode ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="pinCode"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-residingYear">
																Time of Residency (in Years)
															</label>
															<Field
																readOnly={true}
																name="residingYear"
																className={
																	'form-control-alternative form-control' +
																	(errors.residingYear && touched.residingYear
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name="residingYear"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr />
											<h6 className="heading-small text-muted mb-4">Bank Details</h6>
											<div className="pl-lg-4">
												<Row>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-bankname">
																Bank Name
															</label>
															<Field
																readOnly={true}
																id="input-bankName"
																placeholder="Bank Name"
																name="bankName"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.bankName && touched.bankName ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="bankName"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-accountType">
																Type of Account
															</label>
															<Field
																readOnly={true}
																value={typeOfAccount}
																className={
																	'form-control-alternative form-control' +
																	(errors.accountType && touched.accountType
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name="accountType"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-accountNo">
																Account Number
															</label>
															<Field
																readOnly={true}
																id="input-accountNo"
																placeholder="Account Number"
																name="accountNo"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.accountNo && touched.accountNo ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="accountNo"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-routingNo">
																Routing Number
															</label>
															<Field
																readOnly={true}
																id="input-routingNo"
																placeholder="Routing Number"
																name="routingNo"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.routingNo && touched.routingNo ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="routingNo"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="12">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-email">
																Address
															</label>
															<Field
																readOnly={true}
																as="textarea"
																placeholder="Address"
																rows="4"
																name="bankAddress"
																className={
																	'form-control-alternative form-control' +
																	(errors.bankAddress && touched.bankAddress
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name="bankAddress"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr />
											<h6 className="heading-small text-muted mb-4">Loan Detail</h6>
											<div className="pl-lg-4">
												<Row>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-initialAmount">
																Loan Amount
															</label>
															<Field
																readOnly={true}
																id="input-initialAmount"
																placeholder="Initial Amount"
																name="initialAmount"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.initialAmount && touched.initialAmount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name="initialAmount"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-months">
																Tenure
															</label>
															<Field
																readOnly={true}
																name="months"
																value={loanDetail.months + ' months'}
																className={
																	'form-control-alternative form-control' +
																	(errors.months && touched.months ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="months"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-repaymentMonth">
																Installments
															</label>
															<Field
																readOnly={true}
																id="input-bankname"
																placeholder="Repayment per Month"
																name="repaymentMonth"
																type="text"
																className={
																	'form-control-alternative form-control' +
																	(errors.repaymentMonth && touched.repaymentMonth
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name="repaymentMonth"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-bankname">
																Loan Type
															</label>

															<Field
																readOnly={true}
																name="loanType"
																className={
																	'form-control-alternative form-control text-capitalize' +
																	(errors.loanType && touched.loanType ? ' error-box is-invalid' : '')
																}
															/>
															<ErrorMessage
																name="loanType"
																component="div"
																className="is-invalid invalid-feedback"
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr />
											<h6 className="heading-small text-muted mb-4">Documents</h6>
											<div className="pl-lg-4">
												<Row>
													{frontBase64 || backBase64 || addressBase64 || selfieBase64 ? (
														<>
															{frontBase64 && (
																<Col lg="6">
																	<FormGroup>
																		<Row>
																			<Col md="12">
																				<img width="220" height="150" src={frontBase64} alt="" />
																			</Col>
																			<Col md="6" sm="12" className="mt-2">
																				{!props.match.path.endsWith('create') && (
																					<>
																						<Button
																							onClick={(e) => downloadImage(e, frontBase64)}
																							className="text-black btn-block"
																						>
																							<i className="fas fa-download text-info"></i>
																						</Button>
																					</>
																				)}
																			</Col>
																		</Row>
																	</FormGroup>
																</Col>
															)}
															{backBase64 && (
																<Col lg="6">
																	<FormGroup>
																		<Row>
																			<Col md="12">
																				<img width="220" height="150" src={backBase64} alt="" />
																			</Col>
																			<Col md="6" sm="12" className="mt-2">
																				{!props.match.path.endsWith('create') && (
																					<>
																						<Button
																							onClick={(e) => downloadImage(e, backBase64)}
																							className="btn-block text-black"
																						>
																							<i className="fas fa-download text-info"></i>
																						</Button>
																					</>
																				)}
																			</Col>
																		</Row>
																	</FormGroup>
																</Col>
															)}
															{addressBase64 && (
																<Col lg="6">
																	<FormGroup>
																		<Row>
																			<Col md="12">
																				<img width="220" height="150" src={addressBase64} alt="" />
																			</Col>
																			<Col md="6" sm="12" className="mt-2">
																				{!props.match.path.endsWith('create') && (
																					<>
																						<Button
																							onClick={(e) => downloadImage(e, addressBase64)}
																							className="btn-block text-black"
																						>
																							<i className="fas fa-download text-info"></i>
																						</Button>
																					</>
																				)}
																			</Col>
																		</Row>
																	</FormGroup>
																</Col>
															)}
															{selfieBase64 && (
																<Col lg="6">
																	<FormGroup>
																		<Row>
																			<Col md="12">
																				<img width="220" height="150" src={selfieBase64} alt="" />
																			</Col>
																			<Col md="6" sm="12" className="mt-2">
																				{!props.match.path.endsWith('create') && (
																					<>
																						<Button
																							onClick={(e) => downloadImage(e, selfieBase64)}
																							className="text-black btn-block"
																						>
																							<i className="fas fa-download text-info"></i>
																						</Button>
																					</>
																				)}
																			</Col>
																		</Row>
																	</FormGroup>
																</Col>
															)}
														</>
													) : (
														<Col lg="12" className="text-center">
															<div className="font-weight-bold">No documents found</div>
														</Col>
													)}
												</Row>
											</div>
											<hr className="my-4" />
											<div className="pl-lg-4">
												<Row>
													<Col md="12" className="text-center">
														<div className="custom-control custom-control-alternative custom-checkbox">
															<input
																className="custom-control-input"
																id=" customCheckLogin"
																type="checkbox"
																onChange={(e) => onAcceptTc(e)}
															/>
															<label className="custom-control-label" htmlFor=" customCheckLogin">
																<span className="text-muted">
																	I here by accept all 'Terms & Condition' & 'Security & Privacy'
																</span>
																{!isTCAccept && (
																	<span className="is-invalid invalid-feedback">
																		Please accept Terms & Conditions
																	</span>
																)}
															</label>
														</div>
													</Col>
													<Col md="12" className="text-right">
														<Link to={'/application'}>
															<Button className="mr-3 text-black">Cancel</Button>
														</Link>
														<Button type="submit" className="text-white btn-info">
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				)}
			/>
		</>
	);
};

export default LoanView;
