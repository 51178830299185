import constant from './constants.js';
import { getToken, logout } from './dataService.js';

export var loginAPI = (data) => {
    return fetch(constant.API_URL + '/api/login', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-Type': 'application/json' },
    })
        .then((res) => res.json())
        .then(
            (result) => result,
            (err) => err
        );
};

export var forgotPasswordAPI = (data) => {
    return fetch(constant.API_URL + '/api/forgot-password', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-Type': 'application/json' },
    })
        .then((res) => res.json())
        .then(
            (result) => result,
            (err) => err
        );
};

export var resetPasswordAPI = (data) => {
    return fetch(constant.API_URL + '/api/reset-password', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-Type': 'application/json' },
    })
        .then((res) => res.json())
        .then(
            (result) => result,
            (err) => err
        );
};

export var createUserAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users', {
        method: 'POST',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createNoAuthUserAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/no-auth', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createLoanAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan', {
        method: 'POST',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateLoanAPI = (id, data, disableSpinner = false) => {
    !disableSpinner && showSpinner();
    return fetch(constant.API_URL + '/api/loan/' + id, {
        method: 'PUT',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                !disableSpinner && hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllLoanAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var uploadDocumentByLoanId = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/upload-document', {
        method: 'POST',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getLoanByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/loan/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getDashboardDataApi = (data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/loan/dashboard-data', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getLoanByUserIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/loan-userId/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateLoanStatusAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/status/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateMultipleLoanStatusAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteLoanAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/' + id, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateDocumentStatusByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/document/status/' + id, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllUserAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateUserAPI = (id, data) => {
    console.log(id, data);
    showSpinner();
    return fetch(constant.API_URL + '/api/users/' + id, {
        method: 'PUT',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateUserStatusAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/status/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateMultipleUserStatusAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteUserAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/' + id, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getUserByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/users/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createContactAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllContactAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getContactByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteContactAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us/' + id, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteMultipleContactAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateAdminAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/admin/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAdminByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/admin/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createOrUpdateBankDetailsAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/link-bank-details', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getBankDetailsByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/link-bank-details/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getBankDetailsByLoanIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/link-bank-details/load-id' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createLoanAndUserAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/user-loan', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var checkForEmail = (data, disableSpinner) => {
    showSpinner(disableSpinner);
    return fetch(constant.API_URL + '/api/check-email-exist', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else if (res && res.status === 500) {
                return res.json();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createWalletAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/wallet', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getWalletByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/wallet/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getWalletByUseridAPI = (id) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/wallet-userId/' + id, {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            isClient: true,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

const showSpinner = (disableSpinner = false) => {
    if (!disableSpinner) {
        if (document.getElementById('main-content')) document.getElementById('main-content').style.display = 'none';
        if (document.querySelector('.spinner')) document.querySelector('.spinner').style.display = 'block';
    }
};

const hideSpinner = () => {
    if (document.getElementById('main-content')) document.getElementById('main-content').style.display = 'block';
    if (document.querySelector('.spinner')) document.querySelector('.spinner').style.display = 'none';
};
