import React from 'react';

import { toast } from 'react-toastify';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Row,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
} from 'reactstrap';

import { resetPasswordAPI } from '../services/api';
import { useParams } from 'react-router-dom';
import { logout } from 'services/dataService';

const ResetPassword = () => {
    const params = useParams();
    const formFields = {
        // oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
    const validationSchema = Yup.object().shape({
        // oldPassword: Yup.string()
        // 	.min(8, 'Old Password must be at least 8 characters')
        // 	.required('Old Password is required'),
        newPassword: Yup.string()
            .min(8, 'New Password must be at least 8 characters')
            .required('New Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'New Password must match with confirm password')
            .required('Confirm Password is required'),
    });

    var addUser = (data) => {
        resetPasswordAPI({ ...data, userId: params?.userId }).then((result) => {
            if (result && result.success) {
                toast.success(result && result.message);
                setTimeout(() => {
                    logout();
                }, 3000);
            } else {
                toast.error(result && result.message);
                return;
            }
        });
    };

    return (
        <>
            <Col lg="5" md="12" sm="12">
                <Card className="login-box border-0">
                    <CardHeader className="bg-transparent pb-4">
                        <div className="text-muted text-center mt-2 mb-3">
                            <img
                                alt="..."
                                width="170"
                                height="90"
                                src={require('../assets/dashboard_assets/img/brand/logo.png').default}
                            />
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-4 py-lg-4">
                        <Formik
                            onSubmit={(values, actions) => {
                                addUser(values);
                            }}
                            initialValues={formFields}
                            enableReinitialize
                            validationSchema={validationSchema}
                            render={({ handleSubmit, errors, touched }) => (
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        {/* <Col lg='12'>
											<FormGroup>
												<InputGroup className='input-group-alternative'>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText>
															<i className='fas fa-user' />
														</InputGroupText>
													</InputGroupAddon>
													<Field
														id='input-last-name'
														placeholder='Old Password'
														name='oldPassword'
														type='password'
														className={
															'form-control-alternative form-control' +
															(errors.oldPassword && touched.oldPassword
																? ' error-box is-invalid'
																: '')
														}
													/>
												</InputGroup>
												<ErrorMessage
													name='oldPassword'
													component='div'
													className='is-invalid invalid-feedback'
												/>
											</FormGroup>
										</Col> */}
                                        <Col lg="12">
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-lock" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        id="input-password"
                                                        placeholder="New Password"
                                                        type="password"
                                                        name="newPassword"
                                                        className={
                                                            'form-control-alternative form-control' +
                                                            (errors.newPassword && touched.newPassword
                                                                ? ' error-box is-invalid'
                                                                : '')
                                                        }
                                                    />
                                                </InputGroup>
                                                <ErrorMessage
                                                    name="newPassword"
                                                    component="div"
                                                    className="is-invalid invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="12">
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-lock" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        id="input-confirm-password"
                                                        placeholder="Confirm password"
                                                        type="password"
                                                        name="confirmPassword"
                                                        className={
                                                            'form-control-alternative form-control' +
                                                            (errors.confirmPassword && touched.confirmPassword
                                                                ? ' error-box is-invalid'
                                                                : '')
                                                        }
                                                    />
                                                </InputGroup>
                                                <ErrorMessage
                                                    name="confirmPassword"
                                                    component="div"
                                                    className="is-invalid invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <Button
                                            // onClick={(e) => e.preventDefault()}
                                            className="mt-4 mb-2 btn-success text-white"
                                            type="submit"
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                </form>
                            )}
                        />
                    </CardBody>
                </Card>
            </Col>

            <Col lg="5" md="12" sm="12" className="login-img-margin">
                <div className="row gy-4">
                    <img
                        src={require('../assets/dashboard_assets/img/brand/login-img.png').default}
                        className="img-fluid"
                        alt=""
                    />
                </div>
            </Col>
        </>
    );
};

export default ResetPassword;
