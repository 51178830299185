import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumbs';
import { toast } from 'react-toastify';
import { getAdminByIdAPI,getUserByIdAPI, updateAdminAPI,updateUserAPI } from '../services/api';
import { getToken } from 'services/dataService';
import { logout } from 'services/dataService';

const Profile = (props) => {
	const [adminDetails, setAdminDetails] = useState({});
	const [base64, setBase64] = useState(null);
	const [image, setImage] = useState(null);
	const formFields = {
		firstName: adminDetails ? adminDetails.firstName : '',
		lastName: adminDetails ? adminDetails.lastName : '',
		middleName: adminDetails ? adminDetails.middleName : '',
		phoneNo1 : adminDetails ? adminDetails.phoneNo1 : '',
		address1 : adminDetails ? adminDetails.address1 : '',
		email : adminDetails ? adminDetails.email : '',
		oldPassword: 'old-password',
		oldPasswordHash: adminDetails ? adminDetails.password : '',
		newPassword: '',
		confirmPassword: '',
	};
	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		oldPassword: Yup.string()
			.min(6, 'Password must be at least 6 characters')
			.required('Password is required'),
		newPassword: Yup.string(),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref('newPassword'), null],
			'Passwords must match'
		),
	});

	var updateUser = (data) => {
		const formData = new FormData();
		formData.append('data', JSON.stringify(data));
		formData.append('image', image);
		updateUserAPI(adminDetails.id, formData).then((result) => {
			if (result && result.success) {
				if (result.code === 'password_changed') {
					toast.success(result.message);
					logout();
				} else {
					toast.success('Record updated successfully.');
					getAdmin();
				}
			} else {
				console.log(result)
				// toast.error(result.message);
			}
		});
	};

	const getAdmin = () => {
		if (getToken()) {
			const tokenData = jwtDecode(getToken());
			getUserByIdAPI(tokenData.user.id).then((data) => {
				setAdminDetails(data);
				setBase64(data.image);
			});
		}
	};

	const getImage = (e) => {
		const file = e.target.files[0];
		const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
		const type = file && e.target.files[0].type;
		
		if (file.size > 2097152) {
			toast.error('File size exceeds maximum limit 2 MB.');
			e.target.value = '';
			setBase64(null);
			return;
		}
		if (file && !allowedType.includes(type)) {
			toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
			e.target.value = '';
			setBase64(null);
			return;
		}
		displayImage(file);
		setImage(file);
	};

	const displayImage = (file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setBase64(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	useEffect(() => {
		getAdmin();
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='Profile' />
			{/* Page content */}
			<Formik
				onSubmit={(values, actions) => {
					updateUser(values);
				}}
				enableReinitialize
				initialValues={formFields}
				validationSchema={validationSchema}
				render={({ handleSubmit, errors, touched }) => (
					<Container className='mt--7' fluid>
						<Row>
							<Col className='order-xl-1' xl='12'>
								<Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8'>
												<h3 className='mb-0'>My account</h3>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																First name
															</label>
															<Field
																name='firstName'
																type='text'
																id='input-first-name'
																placeholder='First name'
																className={
																	'form-control-alternative form-control' +
																	(errors.firstName &&
																	touched.firstName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='firstName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-middle-name'>
																Middle name
															</label>
															<Field
																id='input-middle-name'
																placeholder='Middle name'
																name='middleName'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.middleName &&
																	touched.middleName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='middleName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-last-name'>
																Last name
															</label>
															<Field
																id='input-last-name'
																placeholder='Last name'
																name='lastName'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.lastName &&
																	touched.lastName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='lastName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-email'>
																Email
															</label>
															<Field
																id='input-email'
																placeholder='Email'
																name='email'
																type='text'
																readOnly
																className={
																	'form-control-alternative form-control' +
																	(errors.email &&
																	touched.email
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='email'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-phone'>
																Phone
															</label>
															<Field
																id='input-phone'
																placeholder='Phone'
																name='phoneNo1'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.phoneNo1 &&
																	touched.phoneNo1
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='phoneNo1'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-address'>
																Address
															</label>
															<Field
																id='input-address'
																placeholder='Address'
																name='address1'
																type='text'
																className={
																	'form-control-alternative form-control' +
																	(errors.address1 &&
																	touched.address1
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='address1'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='customFileLang'>
																Image
															</label>
															<div className='custom-file'>
																<input
																	type='file'
																	className='custom-file-input file-input'
																	id='customFileLang'
																	lang='en'
																	onChange={(e) => getImage(e)}
																/>
																<label
																	className='custom-file-label'
																	htmlFor='customFileLang'></label>
															</div>
														</FormGroup>
													</Col>
													{base64 && (
														<Col lg='12'>
															<FormGroup>
																<img
																	width='100'
																	height='100'
																	src={base64}
																	alt=''
																/>
															</FormGroup>
														</Col>
													)}
												</Row>
											</div>
											<hr className='my-4' />
											{/* Change password */}
											<h6 className='heading-small text-muted mb-4'>
												Change Password
											</h6>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Old Password
															</label>
															<Field
																className='form-control-alternative form-control'
																name='oldPassword'
																placeholder='Old Password'
																type='password'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-last-name'>
																New Password
															</label>
															<Field
																id='input-new-password-name'
																placeholder='New password'
																name='newPassword'
																type='password'
																className={
																	'form-control-alternative form-control' +
																	(errors.newPassword &&
																	touched.newPassword
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='newPassword'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-last-name'>
																Confirm Password
															</label>
															<Field
																id='input-confirm-password-name'
																placeholder='Confirm password'
																name='confirmPassword'
																type='password'
																className={
																	'form-control-alternative form-control' +
																	(errors.confirmPassword &&
																	touched.confirmPassword
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='confirmPassword'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/dashboard'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															Submit
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				)}
			/>
		</>
	);
};

export default Profile;
