import React from 'react';

import { toast } from 'react-toastify';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Row,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from 'reactstrap';

import { setToken } from '../services/dataService';
import { loginAPI } from '../services/api';
import { Link, useHistory } from 'react-router-dom';

const Login = () => {
	var email, password;
	const history = useHistory();
	const handleLogin = () => {
		if (email.value !== undefined && password.value !== undefined) {
			var loginObj = {
				email: email.value,
				password: password.value,
			};
			loginAPI(loginObj).then((result) => {
				if (result && result.result === 'fail') {
					toast.error(result.msg);
				} else {
					setToken(result.token);
					history.push('/user');
				}
			});
		}
	};

	return (
		<>
			<Col lg='5' md='12' sm='12'>
				<Card className='login-box border-0'>
					<CardHeader className='bg-transparent pb-4'>
						<div className='text-muted text-center mt-2 mb-3'>
							<img
								alt='...'
								width='170'
								height='90'
								src={
									require('../assets/dashboard_assets/img/brand/logo.png')
										.default
								}
							/>
						</div>
					</CardHeader>
					<CardBody className='px-lg-4 py-lg-4'>
						<Form role='form'>
							<FormGroup className='mb-3'>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='fas fa-envelope' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										name='email'
										innerRef={(a) => {
											email = a;
										}}
										placeholder='Email'
										type='email'
										autoComplete='new-email'
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='fas fa-lock' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										name='password'
										innerRef={(a) => {
											password = a;
										}}
										placeholder='Password'
										type='password'
										autoComplete='new-password'
									/>
									{/* <InputGroupAddon addonType='postpend'> */}
										<InputGroupText>
											<Link to="/forgot-password">
												Forgot Password ?
											</Link>
										</InputGroupText>
									{/* </InputGroupAddon> */}
								</InputGroup>
							</FormGroup>
							{/* <div className='custom-control custom-control-alternative custom-checkbox'> */}
							{/* <input
									className='custom-control-input'
									id=' customCheckLogin'
									type='checkbox'
								/> */}
							{/* <label
									className='custom-control-label'
									htmlFor=' customCheckLogin'>
									<span className='text-muted'>Remember me</span>
								</label> */}
							{/* </div> */}
							<Row className='mt-3'>
								{/* <Col xs='6'>
									<a
										className='text-light'
										href='#pablo'
										onClick={(e) => e.preventDefault()}>
										<small>Forgot password?</small>
									</a>
								</Col> */}
								<Col className='text-right' xs='12'>
									<span>Don't have an account yet ? </span>
									<Link to='/signup' className='text-blue'>
										<span>Sign Up</span>
									</Link>
								</Col>
							</Row>
							<div className='text-center'>
								<Button
									onClick={handleLogin}
									className='mt-4 mb-2 btn-success text-white'
									type='button'>
									Login
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>

			<Col lg='5' md='12' sm='12' className='login-img-margin'>
				<div className='row gy-4'>
					<img
						src={
							require('../assets/dashboard_assets/img/brand/login-img.png')
								.default
						}
						className='img-fluid'
						alt=''
					/>
				</div>
			</Col>
		</>
	);
};

export default Login;
