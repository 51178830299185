import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import { getDashboardDataApi } from 'services/api';
import NumberFormat from 'react-number-format';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';

import Breadcrumbs from '../components/Breadcrumbs';
const Dashboard = (props) => {
	const [dashboardData, setDashboardData] = useState(null);

	useEffect(() => {
		const tokenData = jwtDecode(getToken());
		getDashboardDataApi({userId : tokenData.user.id}).then((result) => {
			if (result) {
				setDashboardData(result);
			}
		});
	}, []);
	return (
		<>
			<Breadcrumbs breadcrumbName='Dashboard' />
			<div className='header bg-custom pb-7'>
				<Container fluid>
					<div className='header-body'>
						<Row className='mt-4'>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Loan Amount
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanAmountOfUser}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Paid Amount
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>0</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Remaining repayment
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanAmountOfUser}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<div style={{ marginTop: '14%' }}></div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Dashboard;
