import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';
import { getToken } from 'services/dataService';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumbs';
import { toast } from 'react-toastify';
import {
	getBankDetailsByIdAPI,
	createOrUpdateBankDetailsAPI,
	getLoanByIdAPI,
} from '../services/api';

const LinkBankDetails = (props) => {
	const { uid, lid } = useParams();
	const history = useHistory();
	const [bankDetails, setBankDetails] = useState({});
	const [loanDetails, setLoanDetails] = useState({});
	const [debitDetails, showDebitDetails] = useState(false);
	const [creditDetails, showCreditDetails] = useState(false);
	const [sQArray, setSQArray] = useState([
		{
			value: 'What was the house number and street name you lived in as child',
			label: 'What was the house number and street name you lived in as child',
		},
		{
			value:
				'What were the last four digits of your childhood telephone number?',
			label:
				'What were the last four digits of your childhood telephone number?',
		},
		{
			value: 'What primary school did you attend?',
			label: 'What primary school did you attend?',
		},
		{
			value: 'In what town or city was your first full time job?',
			label: 'In what town or city was your first full time job?',
		},
		{
			value: 'In what town or city did you meet your spouse or partner?',
			label: 'In what town or city did you meet your spouse or partner?',
		},
	]);
	const tokenData = jwtDecode(getToken());
	const formFields = {
		loadId: props.match.params.lid,
		userId: tokenData ? tokenData.user.id : '',
		bankName: bankDetails ? bankDetails.bankName : '',
		bankAccount: bankDetails ? bankDetails.bankAccount : '',
		userName: bankDetails ? bankDetails.userName : '',
		password: bankDetails ? bankDetails.password : '',
		routingNo: bankDetails ? bankDetails.routingNo : '',
		securityQuestion: bankDetails ? bankDetails.securityQuestion : '',
		answer: bankDetails ? bankDetails.answer : '',
		debitCardHolderName: bankDetails ? bankDetails.debitCardHolderName : '',
		debitExpiryMonth: bankDetails ? bankDetails.debitExpiryMonth : '',
		debitExpiryYear: bankDetails ? bankDetails.debitExpiryYear : '',
		debitCardNo: bankDetails ? bankDetails.debitCardNo : '',
		debitCvv: bankDetails ? bankDetails.debitCvv : '',
		creditCardHolderName: bankDetails ? bankDetails.creditCardHolderName : '',
		creditExpiryMonth: bankDetails ? bankDetails.creditExpiryMonth : '',
		creditExpiryYear: bankDetails ? bankDetails.creditExpiryYear : '',
		creditCardNo: bankDetails ? bankDetails.creditCardNo : '',
		creditCvv: bankDetails ? bankDetails.creditCvv : '',
		bankStatus: '2',
		reason: bankDetails ? bankDetails.reason : '',
	};
	const digitsOnly = (value) => /^\d+$/.test(value);
	const [debitCardSchema, updateDebitCardSchema] = useState('');
	const [creditCardSchema, updateCreditCardSchema] = useState('');

	const initialSchema = Yup.object().shape({
		bankName: Yup.string().required('Name of the bank is required'),
		bankAccount: Yup.string().required('Bank account is required'),
		userName: Yup.string().required('Username is required'),
		password: Yup.string()
			.min(6, 'Password must be at least 6 characters')
			.required('Password is required'),
		routingNo: Yup.string()
			.min(9, 'Please enter at least 9 characters')
			.required('Routing no is required'),
		securityQuestion: Yup.string().required('Security question is required'),
		answer: Yup.string().required('Answer is required'),
		bankStatus: Yup.string().required('Please select at least one status'),
		debitCardNo: debitCardSchema
			? Yup.string()
					.required('Debit card no is required')
					.test(
						'Digits only',
						'Debit card no should be digit only.',
						digitsOnly
					)
			: '',
		debitCardHolderName: debitCardSchema
			? Yup.string().required('Debit card holder name is required')
			: '',
		debitExpiryMonth: debitCardSchema
			? Yup.string()
					.required('Expiry month is required')
					.test('Digits only', 'Only Digits are allowed.', digitsOnly)
			: '',
		debitExpiryYear: debitCardSchema
			? Yup.string()
					.required('Expiry year is required')
					.test('Digits only', 'Only Digits are allowed.', digitsOnly)
			: '',
		debitCvv: debitCardSchema
			? Yup.string()
					.required('Debit card cvv is required')
					.test(
						'Digits only',
						'Debit card no should be digit only.',
						digitsOnly
					)
					.min(3, 'Cvv 3 digits long')
					.max(3, 'Cvv 3 digits long')
			: '',
		creditCardNo: creditCardSchema
			? Yup.string()
					.required('Credit card no is required')
					.test('Digits only', 'Only Digits are allowed.', digitsOnly)
			: '',
		creditCardHolderName: creditCardSchema
			? Yup.string().required('Credit card holder name is required')
			: '',
		creditExpiryMonth: creditCardSchema
			? Yup.string()
					.required('Expiry month is required')
					.test('Digits only', 'Only Digits are allowed.', digitsOnly)
			: '',
		creditExpiryYear: creditCardSchema
			? Yup.string()
					.required('Expiry year is required')
					.test('Digits only', 'Only Digits are allowed.', digitsOnly)
			: '',
		creditCvv: creditCardSchema
			? Yup.string()
					.required('Credit card cvv is required')
					.test('Digits only', 'Only Digits are allowed.', digitsOnly)
					.min(3, 'Cvv 3 digits long')
					.max(3, 'Cvv 3 digits long')
			: '',
	});

	const onHandleChange = (e) => {
		if (e.target.name === 'isEnableDebitCard') {
			if (e.target.value === 'Yes') {
				showDebitDetails(true);
				updateDebitCardSchema(true);
			} else {
				updateDebitCardSchema(false);
				showDebitDetails(false);
			}
		}
		if (e.target.name === 'isEnableCreditCard') {
			if (e.target.value === 'Yes') {
				showCreditDetails(true);
				updateCreditCardSchema(true);
			} else {
				showCreditDetails(false);
				updateCreditCardSchema(false);
			}
		}
	};

	var updateUser = (data) => {
		createOrUpdateBankDetailsAPI(data).then((result) => {
			if (result && result.success) {
				toast.success(result.message);
				history.push('/application');
			} else {
				toast.error(result.message);
			}
		});
	};

	const getBankDetails = () => {
		getBankDetailsByIdAPI(props.match.params.lid).then((data) => {
			setBankDetails(data);
			data && data.debitCardNo && showDebitDetails(true);
			data && data.debitCardNo
				? updateDebitCardSchema(true)
				: updateDebitCardSchema(false);
			data && data.creditCardNo && showCreditDetails(true);
			data && data.creditCardNo
				? updateCreditCardSchema(true)
				: updateCreditCardSchema(false);
		});
	};

	useEffect(() => {
		getBankDetails();
		getLoanByIdAPI(lid).then((data) =>
			setLoanDetails(Array.isArray(data) && data[0])
		);
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='Link Bank Details' />
			{/* Page content */}
			<Formik
				onSubmit={(values, actions) => {
					updateUser(values);
				}}
				enableReinitialize
				initialValues={formFields}
				validationSchema={initialSchema}
				render={({ handleSubmit, errors, touched }) => (
					<Container className='mt--7' fluid>
						<Row>
							<Col className='order-xl-1' xl='12'>
								<Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8'>
												<h3 className='mb-0'>Link Bank Details</h3>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Username
															</label>
															<Field
																name='userName'
																autoComplete='off'
																type='text'
																placeholder='Username'
																className={
																	'form-control-alternative form-control' +
																	(errors.userName && touched.userName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='userName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Password
															</label>
															<Field
																name='password'
																type='password'
																placeholder='Password'
																className={
																	'form-control-alternative form-control' +
																	(errors.password && touched.password
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='password'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Name of the bank
															</label>
															<Field
																name='bankName'
																type='text'
																placeholder='Name of the bank'
																className={
																	'form-control-alternative form-control' +
																	(errors.bankName && touched.bankName
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='bankName'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Bank Account Number
															</label>
															<Field
																name='bankAccount'
																type='text'
																placeholder='Bank Account Number'
																className={
																	'form-control-alternative form-control' +
																	(errors.bankAccount && touched.bankAccount
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='bankAccount'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Routing no
															</label>
															<Field
																name='routingNo'
																type='text'
																placeholder='Routing no'
																className={
																	'form-control-alternative form-control' +
																	(errors.routingNo && touched.routingNo
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='routingNo'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-confirm-password'>
																Security Question
															</label>
															<Field
																as='select'
																name='securityQuestion'
																className={
																	'form-control-alternative form-control' +
																	(errors.securityQuestion &&
																	touched.securityQuestion
																		? ' error-box is-invalid'
																		: '')
																}>
																<option>Select</option>
																{sQArray.map((e, key) => {
																	return (
																		<option key={key} value={e.value}>
																			{e.label}
																		</option>
																	);
																})}
															</Field>
															<ErrorMessage
																name='securityQuestion'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-first-name'>
																Answer
															</label>
															<Field
																name='answer'
																type='text'
																placeholder='Answer'
																className={
																	'form-control-alternative form-control' +
																	(errors.answer && touched.answer
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='answer'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>

													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-isPastLoan'>
																Do You Have Debit Card of{' '}
																{loanDetails?.bankName} Account Number Ending
																With {loanDetails?.accountNo}?
															</label>
															<div onChange={(e) => onHandleChange(e)}>
																<input
																	type='radio'
																	value='Yes'
																	name='isEnableDebitCard'
																	defaultChecked={debitDetails}
																/>{' '}
																Yes &nbsp;
																<input
																	defaultChecked={!debitDetails}
																	type='radio'
																	value='No'
																	name='isEnableDebitCard'
																/>{' '}
																No
															</div>
														</FormGroup>
													</Col>

													{debitDetails && (
														<>
															<Col className='p-0' lg='12'>
																<h6 className='heading-small text-muted mb-4'>
																	Link Debit card
																</h6>
															</Col>
															<Col lg='6'>
																<FormGroup>
																	<label
																		className='form-control-label'
																		htmlFor='input-first-name'>
																		Debit card holder name
																	</label>
																	<Field
																		name='debitCardHolderName'
																		type='text'
																		placeholder='Debit card holder name'
																		className={
																			'form-control-alternative form-control' +
																			(errors.debitCardHolderName &&
																			touched.debitCardHolderName
																				? ' error-box is-invalid'
																				: '')
																		}
																	/>
																	<ErrorMessage
																		name='debitCardHolderName'
																		component='div'
																		className='is-invalid invalid-feedback'
																	/>
																</FormGroup>
															</Col>
															<Col lg='6'>
																<FormGroup>
																	<label
																		className='form-control-label'
																		htmlFor='input-first-name'>
																		Debit card no
																	</label>
																	<Field
																		name='debitCardNo'
																		type='text'
																		placeholder='Debit card no'
																		className={
																			'form-control-alternative form-control' +
																			(errors.debitCardNo && touched.debitCardNo
																				? ' error-box is-invalid'
																				: '')
																		}
																	/>
																	<ErrorMessage
																		name='debitCardNo'
																		component='div'
																		className='is-invalid invalid-feedback'
																	/>
																</FormGroup>
															</Col>
															<Col lg='6'>
																<FormGroup>
																	{/* <label
																		className='form-control-label'
																		htmlFor='input-dob'>
																		Date of expiry
																	</label> */}
																	<Row>
																		<Col md='6'>
																			<FormGroup>
																				<label
																					className='form-control-label'
																					htmlFor='input-dob'>
																					Month
																				</label>
																				<Field
																					placeholder='Month'
																					name='debitExpiryMonth'
																					type='text'
																					className={
																						'form-control-alternative form-control' +
																						(errors.debitExpiryMonth &&
																						touched.debitExpiryMonth
																							? ' error-box is-invalid'
																							: '')
																					}
																				/>
																				<ErrorMessage
																					name='debitExpiryMonth'
																					component='div'
																					className='is-invalid invalid-feedback'
																				/>
																			</FormGroup>
																		</Col>
																		<Col md='6'>
																			<FormGroup>
																				<label
																					className='form-control-label'
																					htmlFor='input-dob'>
																					Year
																				</label>
																				<Field
																					placeholder='Year'
																					name='debitExpiryYear'
																					type='text'
																					className={
																						'form-control-alternative form-control' +
																						(errors.debitExpiryYear &&
																						touched.debitExpiryYear
																							? ' error-box is-invalid'
																							: '')
																					}
																				/>
																				<ErrorMessage
																					name='debitExpiryYear'
																					component='div'
																					className='is-invalid invalid-feedback'
																				/>
																			</FormGroup>
																		</Col>
																	</Row>
																</FormGroup>
															</Col>
															<Col lg='6' className='mb-3'>
																<FormGroup>
																	<label
																		className='form-control-label'
																		htmlFor='input-first-name'>
																		Debit card cvv
																	</label>
																	<Field
																		name='debitCvv'
																		type='text'
																		placeholder='Debit card cvv'
																		className={
																			'form-control-alternative form-control' +
																			(errors.debitCvv && touched.debitCvv
																				? ' error-box is-invalid'
																				: '')
																		}
																	/>
																	<ErrorMessage
																		name='debitCvv'
																		component='div'
																		className='is-invalid invalid-feedback'
																	/>
																</FormGroup>
															</Col>
														</>
													)}

													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-isPastLoan'>
																Do You Have Credit Card of{' '}
																{loanDetails?.bankName} Under Your Name ?
															</label>
															<div onChange={onHandleChange}>
																<input
																	type='radio'
																	defaultChecked={creditDetails}
																	value='Yes'
																	name='isEnableCreditCard'
																/>{' '}
																Yes &nbsp;
																<input
																	defaultChecked={!creditDetails}
																	type='radio'
																	value='No'
																	name='isEnableCreditCard'
																/>{' '}
																No
															</div>
														</FormGroup>
													</Col>

													{creditDetails && (
														<>
															<Col className="p-0" lg='12'>
																<h6 className='heading-small text-muted mb-4'>
																		Link Credit card
																</h6>
															</Col>
															<Col lg='6'>
																<FormGroup>
																	<label
																		className='form-control-label'
																		htmlFor='input-first-name'>
																		Credit card holder name
																	</label>
																	<Field
																		name='creditCardHolderName'
																		type='text'
																		placeholder='Credit card holder name'
																		className={
																			'form-control-alternative form-control' +
																			(errors.creditCardHolderName &&
																			touched.creditCardHolderName
																				? ' error-box is-invalid'
																				: '')
																		}
																	/>
																	<ErrorMessage
																		name='creditCardHolderName'
																		component='div'
																		className='is-invalid invalid-feedback'
																	/>
																</FormGroup>
															</Col>
															<Col lg='6'>
																<FormGroup>
																	<label
																		className='form-control-label'
																		htmlFor='input-first-name'>
																		Credit card no
																	</label>
																	<Field
																		name='creditCardNo'
																		type='text'
																		placeholder='Credit card no'
																		className={
																			'form-control-alternative form-control' +
																			(errors.creditCardNo &&
																			touched.creditCardNo
																				? ' error-box is-invalid'
																				: '')
																		}
																	/>
																	<ErrorMessage
																		name='creditCardNo'
																		component='div'
																		className='is-invalid invalid-feedback'
																	/>
																</FormGroup>
															</Col>
															<Col lg='6'>
																<FormGroup>
																	{/* <label
																		className='form-control-label'
																		htmlFor='input-dob'>
																		Date of expiry
																	</label> */}
																	<Row>
																		<Col md='6'>
																			<FormGroup>
																				<label
																					className='form-control-label'
																					htmlFor='input-dob'>
																					Month
																				</label>
																				<Field
																					placeholder='Month'
																					name='creditExpiryMonth'
																					type='text'
																					className={
																						'form-control-alternative form-control' +
																						(errors.creditExpiryMonth &&
																						touched.creditExpiryMonth
																							? ' error-box is-invalid'
																							: '')
																					}
																				/>
																				<ErrorMessage
																					name='creditExpiryMonth'
																					component='div'
																					className='is-invalid invalid-feedback'
																				/>
																			</FormGroup>
																		</Col>
																		<Col md='6'>
																			<FormGroup>
																				<label
																					className='form-control-label'
																					htmlFor='input-dob'>
																					Year
																				</label>
																				<Field
																					placeholder='Year'
																					name='creditExpiryYear'
																					type='text'
																					className={
																						'form-control-alternative form-control' +
																						(errors.creditExpiryYear &&
																						touched.creditExpiryYear
																							? ' error-box is-invalid'
																							: '')
																					}
																				/>
																				<ErrorMessage
																					name='creditExpiryYear'
																					component='div'
																					className='is-invalid invalid-feedback'
																				/>
																			</FormGroup>
																		</Col>
																	</Row>
																</FormGroup>
															</Col>
															<Col lg='6' className='mb-3'>
																<FormGroup>
																	<label
																		className='form-control-label'
																		htmlFor='input-first-name'>
																		Credit card cvv
																	</label>
																	<Field
																		name='creditCvv'
																		type='text'
																		placeholder='Credit card cvv'
																		className={
																			'form-control-alternative form-control' +
																			(errors.creditCvv && touched.creditCvv
																				? ' error-box is-invalid'
																				: '')
																		}
																	/>
																	<ErrorMessage
																		name='creditCvv'
																		component='div'
																		className='is-invalid invalid-feedback'
																	/>
																</FormGroup>
															</Col>
														</>
													)}
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/application'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															Submit
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				)}
			/>
		</>
	);
};

export default LinkBankDetails;
