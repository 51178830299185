import React from 'react';

import { toast } from 'react-toastify';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from 'reactstrap';

import { setToken } from '../services/dataService';
import { forgotPasswordAPI } from '../services/api';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
	var email;
	// const history = useHistory();

	const handleForgotPassword = () => {
		if (email.value !== undefined) {
			var forgotPassObj = {
				email: email.value,
			};
			forgotPasswordAPI(forgotPassObj).then((result) => {
				console.log(result);
				if (result && !result.success) {
					toast.error(result.message);
				} else {
					toast.success(result.message);
				}
			});
		}
	};

	return (
		<>
			<Col lg='5' md='12' sm='12'>
				<Card className='login-box border-0'>
					<CardHeader className='bg-transparent pb-4'>
						<div className='text-muted text-center mt-2 mb-3'>
							<img
								alt='...'
								width='170'
								height='90'
								src={
									require('../assets/dashboard_assets/img/brand/logo.png')
										.default
								}
							/>
						</div>
					</CardHeader>
					<CardBody className='px-lg-4 py-lg-4'>
						<Form role='form'>
							<FormGroup className='mb-3'>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='fas fa-envelope' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										name='email'
										innerRef={(a) => {
											email = a;
										}}
										placeholder='Email'
										type='email'
										autoComplete='new-email'
									/>
								</InputGroup>
							</FormGroup>
							<div className='text-center'>
								<Button
									onClick={handleForgotPassword}
									className='mt-4 mb-2 btn-success text-white'
									type='button'>
									Submit
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>

			<Col lg='5' md='12' sm='12' className='login-img-margin'>
				<div className='row gy-4'>
					<img
						src={
							require('../assets/dashboard_assets/img/brand/login-img.png')
								.default
						}
						className='img-fluid'
						alt=''
					/>
				</div>
			</Col>
		</>
	);
};

export default ForgotPassword;
