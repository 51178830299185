import React from 'react';
import { Row, Col } from 'reactstrap';

const Footer = () => {
	return (
		<footer className='footer'>
			<Row className='align-items-center justify-content-end'>
				<Col xl='6'>
					<div className='copyright text-center text-xl-right text-muted'>
						© {new Date().getFullYear()}{' '}
						<a
							className='font-weight-bold ml-1 text-green'
							href='#!'
							onClick={() => {
								window.location.replace('/');
							}}>
							Easy Loan America
						</a>
					</div>
				</Col>
			</Row>
		</footer>
	);
};

export default Footer;
