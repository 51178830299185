import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row, Card, CardHeader, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const defaultArray = [
    {
        value: null,
        label: 'Select',
    },
    {
        value: 'delete',
        label: 'Delete',
    },
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'inactive',
        label: 'Inactive',
    },
];
const Tables = ({ data, columns, tableTitle, defaultSortKey, defaultSortDirection, updateMultipleStatus, showSearchBar }) => {
    let tableRef = React.createRef();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectionType, setSelectionType] = useState(null);
    const [show, setShow] = useState(false);
    const [confirmBtnTitle, setConfirmBtnTitle] = useState('');
    const [mainTitle, setMainTitle] = useState('');
    const [showCancel, SetShowCancel] = useState(true);

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
    });

    const { SearchBar } = Search;

    const indication = () => {
        return (
            <div className="text-muted">
                <p>No Records Found!</p>
            </div>
        );
    };

    const defaultSorted = [
        {
            dataField: defaultSortKey,
            order: defaultSortDirection,
        },
    ];

    const submitSelection = () => {
        if (selectionType && selectedRows.length > 0 && selectionType === 'delete') {
            setConfirmBtnTitle('Yes, delete it!');
            setMainTitle('Are you sure?');
            SetShowCancel(true);
        } else if (selectionType && selectedRows.length > 0 && (selectionType === 'active' || selectionType === 'inactive')) {
            setConfirmBtnTitle('Yes, update status!');
            setMainTitle('Are you sure?');
            SetShowCancel(true);
        } else {
            if (selectedRows.length === 0) {
                setMainTitle('Please select at least one checkbox');
            } else {
                setMainTitle('Please provide action type');
            }
            setConfirmBtnTitle('Ok');
            SetShowCancel(false);
        }
        setShow(true);
        SweetAlertFun();
    };

    const confirmSelection = (event) => {
        setShow(false);
        if (selectionType === 'Select' || selectedRows.length === 0) return;
        const mappedSelectedRow = selectedRows.map((item) => item.id);
        updateMultipleStatus(mappedSelectedRow, selectionType);
    };

    const SweetAlertFun = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel={showCancel}
                    confirmBtnText={confirmBtnTitle}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={mainTitle}
                    show={show}
                    onCancel={() => setShow(false)}
                    onConfirm={(e) => confirmSelection(e)}
                ></SweetAlert>
            </>
        );
    };

    return (
        <>
            {/* Table */}
            <Container className="mt--6" fluid>
                <Row>
                    <SweetAlertFun />
                    <Col>
                        <Card className="scrollable-table">
                            <CardHeader>
                                <Row>
                                    <Col md="6">
                                        <h3 className="mb-0">{tableTitle}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <ToolkitProvider bootstrap4 keyField="id" data={data} columns={columns} search>
                                {(props) => (
                                    <div className="py-4">
                                        {showSearchBar && (
                                            <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1 w-100">
                                                <Row>
                                                    <Col md="12 text-right">
                                                        <label>
                                                            {/* Search: */}
                                                            <SearchBar className="form-control-sm" placeholder="" {...props.searchProps} />
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        <BootstrapTable
                                            ref={tableRef}
                                            noDataIndication={indication}
                                            bootstrap4={true}
                                            defaultSorted={defaultSorted}
                                            bordered={false}
                                            pagination={pagination}
                                            {...props.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>{' '}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Tables;
